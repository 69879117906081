
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			selectedCartItemIndex: {
				type: Number,
				default: -1
			},
			selectedDynamicDiscountType: {
				type: String,
				default: 'item'
			}
		},
		data () {
			return {
				options: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'price',
						text: 'fix',
						pluralizationIndex: 2
					},
					{
						value: 'percentage',
						text: 'percentage'
					}
				],
				discountType: null,
				discountValue: null,
				discountQuantity: 0,
				maximumDiscountValue: null,
				applyDiscountOnEachItem: true,
				notes: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			dynamicDiscount () {
				return this.$store.getters.dynamicDiscount
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			settings () {
				return this.$store.state.settings
			}
		},
		watch: {
			applyDiscountOnEachItem () {
				if (this.selectedDynamicDiscountType === 'item') {
					this.discountQuantity = this.cart.items[this.selectedCartItemIndex].quantity
				}
			}
		},
		mounted () {
			this.$root.$on('dynamic-discount-verified', this.processDiscount)
		},
		destroyed () {
			this.$root.$off('dynamic-discount-verified', this.processDiscount)
		},
		methods: {
			preFillValues () {
				if (this.selectedDynamicDiscountType === 'item') {
					this.applyDiscountOnEachItem = this.settings.general.apply_discount_on_each_item
					this.discountQuantity = this.cart.items[this.selectedCartItemIndex].quantity

					if (this.cart.items[this.selectedCartItemIndex].item_discount.length) {
						const appliedDynamicDiscount = this.cart.items[this.selectedCartItemIndex].item_discount.find(i => i.id === this.dynamicDiscount.id)

						if (appliedDynamicDiscount) {
							this.discountType = appliedDynamicDiscount.get_discount_type
							this.discountValue = appliedDynamicDiscount.get_discount_value
							this.applyDiscountOnEachItem = appliedDynamicDiscount.apply_discount_on_each_item
							this.notes = appliedDynamicDiscount.notes
						}
					}
				} else if (this.cart.selectedDiscount && this.dynamicDiscount && this.cart.selectedDiscount.id === this.dynamicDiscount.id) {
					this.discountType = this.cart.selectedDiscount.get_discount_type
					this.discountValue = this.cart.selectedDiscount.get_discount_value
					this.maximumDiscountValue = this.cart.selectedDiscount.max_discount_value
					this.notes = this.cart.selectedDiscount.notes
				}
			},
			calculateCartItemTotal () {
				const item = this.selectedDynamicDiscountType === 'item' ? this.cart.items[this.selectedCartItemIndex] : this.cart.price
				const total = item.subtotal + (this.selectedDynamicDiscountType === 'item' ? item.taxAmount : item.tax)
				const maxDiscount = this.discountType === 'percentage'
					? 100
					: (
						(this.applyDiscountOnEachItem && this.selectedDynamicDiscountType === 'item')
							? total / item.quantity
							: total).toFixed(2)

				return (this.settings.general.round_off_total && !this.applyDiscountOnEachItem ? Math.round(maxDiscount) : maxDiscount)
			},
			resetForm () {
				this.discountType = null
				this.discountValue = null
				this.maximumDiscountValue = null
				this.discountQuantity = 0
				this.applyDiscountOnEachItem = null
				this.notes = null
			},
			async applyDiscount () {
				if (await this.$refs.validator.validate()) {
					let dynamicDiscountApplied = false

					for (const i in this.cart.items) {
						for (const j in this.cart.items[i].item_discount) {
							if (this.cart.items[i].item_discount[j].id === this.dynamicDiscount.id) {
								dynamicDiscountApplied = true
								break
							}
						}

						if (dynamicDiscountApplied) {
							break
						}
					}

					if (this.employeePolicy.isAdmin || (this.employeePolicy.cart && this.employeePolicy.cart.includes('apply custom discount'))) {
						this.processDiscount()
					} else if (!dynamicDiscountApplied) {
						this.otpModalType = 'dynamic-discount'
					} else {
						this.processDiscount()
					}
				}
			},
			processDiscount () {
				if (this.selectedDynamicDiscountType === 'item') {
					const itemDiscount = this.cart.items[this.selectedCartItemIndex].item_discount
					const itemIndex = itemDiscount.findIndex(i => i.id === this.dynamicDiscount.id)
					const newItem = { ...this.cart.items[this.selectedCartItemIndex] }

					if (itemIndex !== -1) {
						itemDiscount.pop(itemIndex)
					}

					this.cart.items[this.selectedCartItemIndex].quantity = +this.discountQuantity
					this.cart.items[this.selectedCartItemIndex].item_discount.push({
						id: this.dynamicDiscount.id,
						name: this.dynamicDiscount.name,
						buy_batch_id: null,
						get_batch_id: null,
						buy_condition_type: 'quantity',
						buy_condition_value: 0,
						get_discount_type: this.discountType,
						get_discount_value: +this.discountValue,
						get_discount_quantity: +this.discountQuantity,
						get_discount_max_quantity: +this.discountQuantity,
						quantity: +this.discountQuantity,
						type: 'ITEM',
						notes: this.notes,
						entity: this.dynamicDiscount.entity,
						apply_discount_on_each_item: this.applyDiscountOnEachItem,
						updated_at: new Date()
					})

					if ((newItem.quantity - +this.discountQuantity) !== 0) {
						const newItemIndex = newItem.item_discount.findIndex(i => i.id === this.dynamicDiscount.id)

						newItem.quantity -= +this.discountQuantity

						if (newItemIndex !== -1) {
							newItem.item_discount = []
						}

						this.$store.dispatch('modifyCart', {
							item: {
								id: newItem.item_id,
								name: newItem.item_name,
								category_id: newItem.category_id,
								brand_id: newItem.brand_id
							},
							variation: newItem
						})
					}

					this.$store.commit('setCart', this.cart)
					this.$store.dispatch('cartCalculation')
				} else {
					const orderDynamicDiscount = {
						...this.dynamicDiscount,
						entity: 'all',
						buy_condition_type: 'price',
						buy_condition_value: this.cart.price.subtotal,
						get_discount_type: this.discountType,
						get_discount_value: +this.discountValue,
						max_discount_value: this.discountType === 'price' ? +this.discountValue : +this.maximumDiscountValue,
						notes: this.notes
					}

					this.$emit('update:selectedDiscount', orderDynamicDiscount)
				}

				this.$refs.modal.hide()
			}
		}
	}
