
	let activeEl = null

	export default {
		props: {
			type: {
				type: String,
				required: true
			}
		},
		data () {
			return {
				items: {
					data: [],
					total: 0,
					offset: 0
				},
				searchTerm: '',
				categories: [],
				selectedCategories: [],
				priceCategories: [],
				keystrokes: '',
				keystrokeDiff: null,
				loading: true,
				selectedPriceCategoryId: null,
				itemVariations: [],
				showPriceCategoryModal: false,
				focusedIndex: -1
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			categoryView () {
				return this.$store.state.categoryView
			},
			validSelectedCategories () {
				return this.selectedCategories.filter(c => c.id !== null)
			},
			category () {
				let category = { id: null, name: this.categoryView === 'button' ? 'all' : 'select category' }

				if (this.validSelectedCategories.length) {
					category = this.validSelectedCategories[this.validSelectedCategories.length - 1]
				}

				return category
			},
			selectedPriceCategory: {
				get () {
					return this.$store.state.cart.priceCategory
				},
				set (priceCategory) {
					const labels = priceCategory?.custom_attributes.labels || {}
					const additionalInfo = {}

					for (const key in labels) {
						additionalInfo[
							labels[key].name.toLowerCase().split(' ').join('-')
						] = this.cart.additionalInfo[
							labels[key].name.toLowerCase().split(' ').join('-')
						] || ''
					}

					this.$store.commit('setCart', { priceCategory, additionalInfo })
				}
			},
			cart () {
				return this.$store.state.cart
			},
			favorites () {
				return this.$store.state.favorites
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			weighingScale () {
				return this.$store.state.weighingScale
			},
			isWeighingScaleEnabled () {
				return this.weighingScale.enabled && this.weighingScale.serialPortOptions.path !== null
			},
			checkout () {
				return this.$store.state.checkout
			},
			settings () {
				return this.$store.state.settings
			},
			showItemSearchDropdown () {
				return this.$store.state.showItemSearchDropdown
			},
			paginationSize () {
				return this.$store.state.paginationSize
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			searchTerm () {
				if (this.$refs.itemsBlock) {
					this.$refs.itemsBlock.scrollTop = 0
				}

				this.getItems()
			},
			category () {
				if (this.searchTerm) {
					this.searchTerm = ''
				} else if (this.$refs.itemsBlock) {
					this.$refs.itemsBlock.scrollTop = 0
				}
			},
			async checkout (checkout) {
				if (!checkout.show && this.settings.general.price_category_select_mandatory && this.cart.items.length === 0) {
					this.showPriceCategoryModal = true
				}

				if (checkout.show) {
					this.selectedCategories = [{
						id: null,
						name: this.categoryView === 'button' ? 'all' : 'select category'
					}]
					await this.getCategories()
					await this.getItems()
				}
			},
			selectedPriceCategoryId () {
				this.selectedPriceCategory = this.priceCategories.find(p => p.id === this.selectedPriceCategoryId) || null

				if (this.$refs.itemsBlock) {
					this.$refs.itemsBlock.scrollTop = 0
				}

				this.getItems()
			}
		},
		async beforeMount () {
			await this.getCategories()
			await this.getItems()
			await this.getPriceCategories()
			this.selectedPriceCategoryId = this.selectedPriceCategory?.id || null
			this.loading = false
		},
		mounted () {
			document.addEventListener('keydown', this.keyboardEvents)
			this.$root.$on('checkout-completed', this.onCheckoutCompleted)
			this.$root.$on('bv::modal::hidden', this.hideSheetImportModal)
			this.$root.$on('crisp-toggle', this.toggleCrispChat)
			window.processBarcode = this.processBarcode

			if (this.settings.general.price_category_select_mandatory && this.cart.items.length === 0) {
				this.showPriceCategoryModal = true
			}
		},
		destroyed () {
			document.removeEventListener('keydown', this.keyboardEvents)
			this.$root.$off('checkout-completed', this.onCheckoutCompleted)
			this.$root.$off('bv::modal::hidden', this.hideSheetImportModal)
			this.$root.$off('crisp-toggle', this.toggleCrispChat)
			delete window.processBarcode
		},
		methods: {
			onSelectPriceCategory (selectedOption) {
				this.selectedPriceCategoryId = selectedOption
				this.showPriceCategoryModal = false
			},
			async getItems (offset) {
				let items = await this.$bridge.getItems(this.deviceId, this.locationId, this.objToJson({
					merchant_id: this.merchant.id,
					id: this.type === 'FAVORITES' ? (this.favorites.length ? this.favorites : 0) : null,
					search_term: this.searchTerm,
					price_category_id: this.selectedPriceCategory?.id,
					category_id: this.category.id,
					topSold: this.type === 'TOP',
					limit: this.paginationSize,
					offset
				}))

				items = typeof items === 'string' ? JSON.parse(items) : items

				if (offset) {
					items.data = this.items.data.concat(items.data)
				}

				this.items = items

				this.itemVariations = this.items.data.reduce((variations, item) => {
					item.variations.forEach((variation) => {
						variations.push({
							...item,
							variations: [variation]
						})

						return 0
					})

					return variations
				}, [])
			},
			async getCategories (searchTerm) {
				let categories = await this.$bridge.getCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					search_term: searchTerm,
					has_item: true,
					parent_category_id: this.category.id,
					offset: this.appVersionNumber >= 4010 ? -1 : undefined
				}))

				categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data
				this.categories = searchTerm || this.categoryView === 'select' ? categories : [{ id: null, name: 'all' }].concat(categories)
			},
			async selectCategory ($event) {
				let categoryId = $event

				if (typeof $event === 'object') {
					categoryId = $event?.id
				}

				if (categoryId) {
					if (this.categoryView === 'button') {
						this.selectedCategories.pop()
					}

					this.selectedCategories.push(this.categories.find(c => c.id === categoryId))

					let subCategories = this.appVersionNumber >= 4028
						? await this.$bridge.getCategories(this.deviceId, this.objToJson({
							merchant_id: this.merchant.id,
							parent_category_id: categoryId,
							has_item: true,
							offset: this.appVersionNumber >= 4010 ? -1 : undefined
						}))
						: { data: [] }

					subCategories = (typeof subCategories === 'string' ? JSON.parse(subCategories) : subCategories).data

					if (subCategories.length) {
						if (this.categoryView === 'button') {
							this.selectedCategories.push(this.categories[0])
							this.categories = [this.categories[0]].concat(subCategories)
						} else {
							this.categories = subCategories
							this.$refs.categorySelect.open = true
						}
					}
				} else {
					this.selectedCategories.pop()

					if (this.categoryView === 'button') {
						this.selectedCategories.push(this.categories[0])
					}
				}

				await this.getItems()
			},
			async onPressCategoryBack (index) {
				if (this.categoryView === 'button') {
					this.selectedCategories.splice(index)
					this.selectedCategories.push(this.categories[0])
				} else {
					this.selectedCategories.splice(typeof index === 'number' ? index : -1)
				}

				let categories = await this.$bridge.getCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					parent_category_id: this.category.id,
					has_item: true,
					offset: this.appVersionNumber >= 4010 ? -1 : undefined
				}))

				categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data

				if (this.categoryView === 'button') {
					this.categories = [this.categories[0]].concat(categories)
				} else {
					this.categories = categories
					this.$refs.categorySelect.$el.querySelector('.vs__search').focus()
				}

				await this.getItems()
			},
			searchCategories (searchTerm) {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getCategories(searchTerm)
				}, 100)
			},
			async getPriceCategories () {
				let priceCategories = await this.$bridge.getPriceCategories(
					this.deviceId, this.objToJson({
						merchant_id: this.merchant.id,
						type: 'base'
					})
				)

				priceCategories = (typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories)
				priceCategories = priceCategories.data || priceCategories

				this.priceCategories = this.priceCategories.concat(priceCategories)

				this.selectDefaultPriceCategory()
			},
			selectDefaultPriceCategory () {
				if (this.cart.items.length === 0 && !this.cart.storeOrder) {
					let defaultPriceCategory = null

					if (this.cart.table?.price_category_id) {
						defaultPriceCategory = this.priceCategories.find(pc => pc.id === this.cart.table.price_category_id)
					} else {
						defaultPriceCategory = this.priceCategories.find(pc => pc.is_default)
					}

					if (defaultPriceCategory) {
						this.selectedPriceCategory = defaultPriceCategory
						this.selectedPriceCategoryId = defaultPriceCategory.id
					}
				}

				if (this.cart.priceCategory && this.priceCategories.findIndex(pc => pc.id === this.cart.priceCategory.id) === -1) {
					this.selectedPriceCategory = null
					this.selectedPriceCategoryId = null
				}
			},
			selectItem (item) {
				item = JSON.parse(this.objToJson(item))

				if (item.variations.length === 1 && item.variations[0].modifiers.length === 0 && item.variations[0].batch.length <= 1) {
					let variation = item.variations[0]

					if (variation.batch.length === 1) {
						variation.batch_id = variation.batch[0].batch_id || variation.batch[0].id
						variation.price = variation.batch[0].price
						variation.custom_attributes.mrp = variation.batch[0].mrp
					}

					if (this.appVersionNumber < 3825 && variation.pricing_type === 'multi') {
						variation = this.applyMultiPricing(variation, this.selectedPriceCategory?.id)
					}

					if (variation.price > 0 && variation.pricing_type !== 'variable') {
						const cartItemVariation = this.filterItem(this.cart.items, variation)

						if (this.getUnitMeasureType(variation.unit_measure_type) === 'weight' && !variation.quantity && this.isWeighingScaleEnabled) {
							return this.getWeight((weight) => {
								if (weight) {
									variation.quantity = weight
									this.$store.dispatch('modifyCart', {
										item: {
											id: item.id,
											name: item.name,
											category_id: item.category ? item.category.id : null,
											brand_id: item.brand?.id || null
										},
										variation
									})
								} else {
									return this.$swal({
										title: this.$t('weighingScaleError.title'),
										text: this.$t('weighingScaleError.text'),
										icon: 'error',
										button: this.$t('ok')
									})
								}
							})
						} else {
							variation.quantity = Math.min(9999, (cartItemVariation ? cartItemVariation.quantity : 0) + (variation.quantity || 1))
						}

						this.$store.dispatch('modifyCart', {
							item: {
								id: item.id,
								name: item.name,
								category_id: item.category ? item.category.id : null,
								brand_id: item.brand?.id || null
							},
							variation
						})

						if (this.searchTerm) {
							this.searchTerm = ''
							this.$refs.searchTerm?.focus()
						}

						return
					}
				}

				this.$store.dispatch('selectItem', item)
			},
			loadMoreItems () {
				const limit = this.appVersionNumber >= 4024 ? this.paginationSize : 50
				const nextPage = this.items.offset + limit
				const lastPage = Math.floor(this.items.total / limit) * limit

				if (nextPage <= lastPage) {
					this.getItems(nextPage)
				}
			},
			async processBarcode (barcode) {
				barcode = barcode?.trim()

				if (barcode) {
					const keyData = barcode.split(this.keyboardShortcuts.quantity_delimiter)
					let items = await this.$bridge.getItems(this.deviceId, this.locationId, this.objToJson({
						search_term: keyData[0],
						search_source: 'keyboard',
						price_category_id: this.selectedPriceCategory?.id
					}))

					items = (typeof items === 'string' ? JSON.parse(items) : items).data

					if (items.length === 1) {
						if (items[0].variations.length === 1 && keyData[1]) {
							items[0].variations[0].quantity = parseFloat(parseFloat(keyData[1]).toFixed(
								this.getUnitDecimalPlaces(items[0].variations[0].unit_measure_type)
							))
						}

						this.selectItem(items[0])
					} else {
						let customBarcode = null
						let quantity = 0
						let price = 0

						if (this.settings.barcode.barcode?.start_index && this.settings.barcode.barcode?.end_index) {
							customBarcode = keyData[0].slice(+this.settings.barcode.barcode.start_index - 1, +this.settings.barcode.barcode.end_index)
						}

						if (this.settings.barcode.quantity?.start_index && this.settings.barcode.quantity?.end_index) {
							quantity = +keyData[0].slice(+this.settings.barcode.quantity.start_index - 1, +this.settings.barcode.quantity.end_index)
						}

						if (this.settings.barcode.price?.start_index && this.settings.barcode.price?.end_index) {
							price = +keyData[0].slice(+this.settings.barcode.price.start_index - 1, +this.settings.barcode.price.end_index)
						}

						if (customBarcode) {
							let items = await this.$bridge.getItems(this.deviceId, this.locationId, this.objToJson({
								search_term: customBarcode,
								search_source: ((this.bridgeName === 'ELECTRON' && this.appVersionNumber > 4039) || (this.bridgeName === 'ANDROID' && this.appVersionNumber >= 4028)) && this.settings.general.custom_barcode ? 'custom' : 'keyboard',
								price_category_id: this.selectedPriceCategory?.id
							}))

							items = (typeof items === 'string' ? JSON.parse(items) : items).data

							if (items.length === 1 && items[0].variations.length === 1) {
								if (quantity) {
									if (
										['weight', 'volume', 'length'].includes(
											this.getUnitMeasureType(items[0].variations[0].unit_measure_type)
										) && !quantity.toString().includes('.')
									) {
										quantity = quantity / 1000
									}

									items[0].variations[0].quantity = quantity
								}

								if (price) {
									if (this.settings.barcode.price.type === 'grand_total') {
										items[0].variations[0].price = price / quantity
									} else {
										items[0].variations[0].price = price / (
											price.toString().includes('.') && price.toString().split('.').pop()?.length === 3 ? 1000 : 100
										)
									}
								}

								if (items[0].variations[0].modifiers.length === 0) {
									const variation = items[0].variations[0]

									if (variation.batch.length) {
										variation.batch_id = variation.batch[0].batch_id || variation.batch[0].id
										variation.price = price ? variation.price : variation.batch[0].price
										variation.custom_attributes.mrp = variation.batch[0].mrp
									}

									const cartItemVariation = await this.filterItem(this.cart.items, items[0].variations[0])

									variation.quantity = (cartItemVariation ? cartItemVariation.quantity : 0) + (variation.quantity || 1)
									await this.$store.dispatch('modifyCart', {
										item: {
											id: items[0].id,
											name: items[0].name,
											category_id: items[0].category ? items[0].category.id : null,
											brand_id: items[0].brand?.id || null
										},
										variation
									})

									if (variation.pricing_type === 'variable' || variation.batch.length > 1) {
										setTimeout(() => {
											const index = this.filterItem(this.cart.items, items[0].variations[0], 'index')

											document.querySelector('.cart-items')?.querySelectorAll('.list-group-item')[index]?.click()
										}, 100)
									}
								} else {
									this.selectItem(items[0])
								}
							} else {
								this.$swal({
									title: this.$tc('barcodeItemError.title', items.length > 1 ? 2 : 1),
									text: this.$tc('barcodeItemError.text', items.length > 1 ? 2 : 1, [customBarcode]),
									icon: 'error',
									button: this.$t('ok')
								})
							}
						} else {
							this.$swal({
								title: this.$tc('barcodeItemError.title', 1),
								text: this.$tc('barcodeItemError.text', 1, [keyData[0]]),
								icon: 'error',
								button: this.$t('ok')
							})
						}
					}
				}
			},
			async keyboardEvents (e) {
				if ((e.key?.length === 1 || e.key === 'Enter') && !e.target?.classList.contains('ignore-enter')) {
					const now = Date.now()

					if ((now - this.keystrokeDiff) > 1500) {
						this.keystrokes = ''
					}

					this.keystrokeDiff = now

					if (document.querySelectorAll('.modal.show').length === 0) {
						if (e.key !== 'Enter') {
							this.keystrokes += e.key

							if (!['BODY', 'INPUT'].includes(document.activeElement.tagName)) {
								activeEl = document.activeElement
								activeEl.blur()
							}
						} else if (this.keystrokes.length) {
							if (this.keystrokes[0] !== this.keyboardShortcuts.quantity_delimiter) {
								await this.processBarcode(this.keystrokes)
								this.keystrokes = ''
							} else {
								this.keystrokes = ''
							}

							if (activeEl) {
								activeEl.focus()
								activeEl = null
							}
						}
					}
				}
			},
			onCheckoutCompleted () {
				this.selectDefaultPriceCategory()
			},
			selectPriceCategory (priceCategory) {
				if (
					this.cart.orderType !== 'drive_thru' &&
					this.settings.general.enable_caller_id &&
					priceCategory.slug === 'delivery'
				) {
					this.$bvModal.show('caller-id-modal')
				} else {
					delete this.cart.storeOrder
				}
			},
			toggleCrispChat () {
				if (window.$crisp) {
					if (window.$crisp.is('chat:opened') === true) {
						document.removeEventListener('keydown', this.keyboardEvents)
					} else {
						document.addEventListener('keydown', this.keyboardEvents)
					}
				}
			},
			uploadItemSheet () {
				this.$root.$emit('show-sheet-import-modal', 'Item')
			},
			async hideSheetImportModal (bvEvent, modalId) {
				if (modalId === 'sheet-import-modal') {
					await this.getItems()
				}
			},
			handleItemSearchNavigation (event) {
				if (event.key === 'ArrowDown' && this.showItemSearchDropdown) {
					event.preventDefault()
					this.focusedIndex = 0
					this.$nextTick(() => this.focusItemSearchOption(this.focusedIndex))
				}
			},
			focusItemSearchOption (index) {
				const option = this.$refs['option' + index][0] || this.$refs['option' + index]

				option?.focus()
			},
			navigateItemSearchOptions (direction, event) {
				event.preventDefault()

				if (direction === 'down') {
					this.focusedIndex = (this.focusedIndex + 1) % this.itemVariations.length
				} else if (direction === 'up') {
					this.focusedIndex = (this.focusedIndex - 1 + this.itemVariations.length) % this.itemVariations.length
				}

				this.focusItemSearchOption(this.focusedIndex)
			}
		}
	}
