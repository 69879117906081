
	import DateRangePicker from 'vue2-daterange-picker'

	export default {
		components: {
			DateRangePicker
		},
		data () {
			return {
				loading: true,
				dateRange: {
					startDate: null,
					endDate: null
				},
				employeeShifts: [],
				selectedEmployeeShift: null,
				employees: [],
				employeeId: null,
				salesReport: {},
				topItemBySales: {},
				brandSalesReport: {}
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			settings () {
				return this.$store.state.settings
			},
			paymentMethodSales () {
				return this.salesReport.paymentMethodSales || this.salesReport.paymentMethods
			},
			taxSales () {
				return this.salesReport.tax || this.salesReport.taxes
			},
			tipSales () {
				return this.salesReport.tip || this.salesReport.tips
			},
			channelSales () {
				return this.salesReport.channelSales || this.salesReport.channels
			},
			employeeSales () {
				return this.salesReport.employeeSales || this.salesReport.employees
			},
			creditSales () {
				return this.salesReport.creditSales || this.salesReport.credits
			}
		},
		watch: {
			selectedEmployeeShift () {
				this.getReports()
			}
		},
		async beforeMount () {
			let employees = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
				type: ['admin', 'staff'],
				offset: -1
			}))

			employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
			this.employees = employees.data || employees
			this.employeeShifts = [{ ...this.employeeShift }]
			this.selectedEmployeeShift = { ...this.employeeShift }
		},
		methods: {
			async getEmployeeShifts () {
				const employeeShifts = await this.$store.dispatch('bridgeCall', {
					methodName: 'getEmployeeShifts',
					args: [this.deviceId, this.objToJson({
						device_id: this.deviceId,
						from_date: this.$moment(this.dateRange.startDate).startOf('day').toDate(),
						to_date: this.$moment(this.dateRange.endDate).endOf('day').toDate()
					})]
				})

				this.employeeShifts = typeof employeeShifts === 'string' ? JSON.parse(employeeShifts) : employeeShifts
				this.selectedEmployeeShift = this.employeeShifts[0]
			},
			async getReports () {
				this.loading = true

				let itemsSalesReport = await this.$store.dispatch('bridgeCall', {
					methodName: 'getItemsSalesReport',
					args: [
						this.bridgeName === 'ANDROID'
							? this.objToJson(this.merchant.id)
							: this.merchant.id, this.deviceId, (this.appVersionNumber > 3821 && +this.settings.general.multi_employee_kiosk && this.employeePolicy.isAdmin
							? (this.employeeId || null)
							: this.employee.id), this.selectedEmployeeShift?.id || ''
					]
				})
				const salesReport = await this.$store.dispatch('bridgeCall', {
					methodName: 'getSalesReport',
					args: [
						this.bridgeName === 'ANDROID'
							? this.objToJson(this.merchant.id)
							: this.merchant.id, this.deviceId, (this.appVersionNumber > 3821 && +this.settings.general.multi_employee_kiosk && this.employeePolicy.isAdmin
							? (this.employeeId || null)
							: this.employee.id), this.selectedEmployeeShift?.id || ''
					]
				})

				if (salesReport && itemsSalesReport) {
					itemsSalesReport = typeof itemsSalesReport === 'string' ? JSON.parse(itemsSalesReport) : itemsSalesReport
					this.brandSalesReport = itemsSalesReport.brands
					itemsSalesReport = itemsSalesReport.length ? itemsSalesReport : itemsSalesReport.sales
					this.topItemBySales = itemsSalesReport.sort((a, b) => b.quantity - a.quantity)[0] || {}
					this.salesReport = typeof salesReport === 'string' ? JSON.parse(salesReport) : salesReport
					this.loading = false
				} else {
					this.store.dispatch('checkPrimaryDevice')
				}
			},
			printShift (type) {
				window.printShift({
					type,
					source: 'reports',
					employeeId: this.employeeId,
					employeeShift: this.selectedEmployeeShift
				})
			},
			clearDateRange () {
				this.dateRange = { startDate: null, endDate: null }
				this.employeeShifts = [{ ...this.employeeShift }]
				this.selectedEmployeeShift = { ...this.employeeShift }
			}
		}
	}
