
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				charges: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedCharge: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'value',
						label: 'value',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					},
					{
						key: 'type',
						label: 'type',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				],
				ChargesOnOptions: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'total',
						text: 'total'
					},
					{
						value: 'subtotal',
						text: 'subtotal'
					}
				],
				options: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'fixed',
						text: 'fix',
						pluralizationIndex: 2
					},
					{
						value: 'percentage',
						text: 'percentage'
					}
				],
				categories: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'packing_charge',
						text: 'order packing charges'
					},
					{
						value: 'service_charge',
						text: 'service charges'
					},
					{
						value: 'payment_charge',
						text: 'payment charges'
					}
				],
				taxes: [],
				priceCategories: [],
				applicableOn: null,
				chargeName: null,
				chargeType: null,
				chargeValue: null,
				selectedTaxes: null,
				selectedCategory: null,
				selectedPriceCategories: null,
				applyAutomatically: false
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			settings () {
				return this.$store.state.settings
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isOnline () {
				return this.$store.state.isOnline
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getPriceCategories()
			await this.getCharges()
		},
		methods: {
			async getCharges (isLoadMore = false) {
				let data = await this.$bridge.getCharges(this.deviceId, this.locationId, this.objToJson({
					offset: isLoadMore ? this.charges.offset + 20 : 0
				}))

				data = (typeof data === 'string' ? JSON.parse(data) : data)

				this.charges.data = data.offset ? this.charges.data.concat(data.data) : data.data
				this.charges.offset = data.offset
				this.charges.total = data.total

				this.charges.data = this.charges.data.filter(c => c.category !== 'dynamic_charge')
				this.charges.total -= 1
			},
			async getTaxes () {
				const taxes = await this.$bridge.getTaxes(this.deviceId, '')

				this.taxes = (typeof taxes === 'string' ? JSON.parse(taxes) : taxes).data
			},
			async getPriceCategories () {
				let priceCategories = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					type: 'base'
				}))

				priceCategories = (typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories)
				this.priceCategories = priceCategories.data || priceCategories
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getCharges(true)
				}
			},
			createCharge () {
				this.selectedCharge = null
				this.$refs.chargeModal.show()
			},
			editCharge (charge) {
				this.selectedCharge = charge
				this.initModal()
				this.$refs.chargeModal.show()
			},
			deleteCharge (charge) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						charge = {
							id: charge.id,
							is_active: false,
							updated_at: new Date()
						}

						await this.$bridge.insert(
							'Charge',
							this.bridgeName === 'ANDROID' ? this.objToJson(charge) : charge,
							true
						)
						this.getCharges()
					}
				})
			},
			initModal () {
				if (this.selectedCharge) {
					this.chargeName = this.selectedCharge.name
					this.chargeValue = this.selectedCharge.value
					this.chargeType = this.selectedCharge.type
					this.selectedCategory = this.selectedCharge.category
					this.selectedTaxes = this.selectedCharge.tax
					this.applicableOn = this.selectedCharge.applicable_on
					this.selectedPriceCategories = this.priceCategories.filter(pc => this.selectedCharge.excluded_price_categories.includes(pc.id))
					this.applyAutomatically = this.selectedCharge.is_automatic
				}
			},
			async createOrUpdateCharge () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()
					const charge = {
						id: this.selectedCharge?.id || +date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						location_id: this.locationId,
						name: this.chargeName,
						value: this.chargeValue,
						type: this.chargeType,
						applicable_on: this.applicableOn,
						category: this.selectedCategory,
						tax: this.selectedTaxes || [],
						conditions: this.objToJson({}),
						excluded_price_categories: this.selectedPriceCategories?.map(p => p.id) || [],
						custom_attributes: this.objToJson(this.selectedCharge?.custom_attributes || {}),
						is_active: true,
						is_automatic: this.applyAutomatically,
						updated_at: date
					}

					await this.$bridge.insert(
						'Charge',
						this.bridgeName === 'ANDROID' ? this.objToJson(charge) : charge,
						true
					)
					this.$refs.chargeModal.hide()
					this.getCharges()
				}
			},
			resetModal () {
				this.chargeName = null
				this.applicableOn = null
				this.chargeType = null
				this.chargeValue = null
				this.selectedTaxes = null
				this.selectedCategory = null
				this.selectedPriceCategories = null
				this.applyAutomatically = false
			}
		}
	}
