
	export default {
		data () {
			return {
				activeDiscountId: null
			}
		},
		computed: {
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			showOrderDiscounts: {
				get () {
					return this.$store.state.showOrderDiscounts
				},
				set (value) {
					this.$store.commit('setState', { key: 'showOrderDiscounts', value })
				}
			},
			discounts () {
				return this.$store.getters.orderDiscounts
			}
		},
		methods: {
			async initOrderDiscounts () {
				let discounts = await this.$bridge.getDiscounts(this.deviceId, this.locationId, '')

				discounts = (typeof discounts === 'string' ? JSON.parse(discounts) : discounts)
				discounts = discounts.data || discounts
				this.activeDiscountId = this.cart.selectedDiscount ? this.cart.selectedDiscount.id : null
				this.$store.commit('setState', {
					key: 'discounts',
					value: discounts.filter(d => d.entity !== 'gift_card')
				})
			},
			applyDiscount () {
				this.$emit('update:selectedDiscount', this.discounts.find(
					discount => discount.id === this.activeDiscountId
				))
				this.$refs.discountModal.hide()
			}
		}
	}
