
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				floors: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedFloor: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					},
					{
						key: 'table_count',
						label: 'table count',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					}
				],
				floorName: null
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getFloors()
		},
		methods: {
			async getFloors (isLoadMore = false) {
				let data = await this.$bridge.getFloors(this.deviceId, this.locationId, this.objToJson({
					merchant_id: this.merchant.id,
					offset: isLoadMore ? this.floors.offset + 20 : 0
				}))

				data = (typeof data === 'string' ? JSON.parse(data) : data)

				this.floors.data = data.offset ? this.floors.data.concat(data.data) : data.data || data
				this.floors.offset = data.offset || 0
				this.floors.total = data.total || data.data.length
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getFloors(true)
				}
			},
			createFloor () {
				this.selectedFloor = null
				this.$refs.floorModal.show()
			},
			editFloor (floor) {
				this.selectedFloor = floor
				this.$refs.floorModal.show()
			},
			deleteFloor (floor) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						floor = {
							id: floor.id,
							is_active: false,
							tables: floor.tables.map(t => ({
								id: t.id,
								is_active: false,
								updated_at: new Date()
							})),
							updated_at: new Date()
						}

						await this.$bridge.insert(
							'Floor',
							this.bridgeName === 'ANDROID' ? this.objToJson(floor) : floor,
							true
						)
						this.getFloors()
					}
				})
			},
			initModal () {
				if (this.selectedFloor) {
					this.floorName = this.selectedFloor?.name || null
				}
			},
			async createOrUpdateFloor () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()
					const floor = {
						id: this.selectedFloor?.id || +date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						location_id: this.locationId,
						tables: this.selectedFloor?.tables.map(t => ({
							...t,
							layout: this.objToJson(t.layout),
							custom_attributes: this.objToJson(t.custom_attributes)
						})),
						name: this.floorName,
						layout: this.objToJson(this.selectedFloor?.layout || {}),
						custom_attributes: this.objToJson(this.selectedFloor?.custom_attributes || {}),
						updated_at: date
					}

					await this.$bridge.insert(
						'Floor',
						this.bridgeName === 'ANDROID' ? this.objToJson(floor) : floor,
						true
					)

					this.$refs.floorModal.hide()
					this.getFloors()
				}
			},
			resetModal () {
				this.floorName = null
			}
		}
	}
