
	export default {
		data () {
			return {
				loading: false,
				serialPorts: [],
				weight: '-'
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			baudRates () {
				return this.$store.state.baudRates
			},
			weighingScale: {
				get () {
					return this.$store.state.weighingScale
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'weighingScale',
						value,
						save: true
					})
				}
			}
		},
		watch: {
			weighingScale: {
				handler (value) {
					this.weighingScale = value
				},
				deep: true
			}
		},
		methods: {
			setHardware ($event) {
				if ($event === 'magellan-9800i') {
					this.weighingScale = {
						...this.weighingScale,
						hardware: $event,
						serialPortOptions: {
							...this.weighingScale.serialPortOptions,
							baudRate: 9600,
							parity: 'even',
							dataBits: 7,
							stopBits: 1,
							command: 'W'
						}
					}
				} else {
					this.weighingScale = {
						...this.weighingScale,
						hardware: $event,
						serialPortOptions: {
							...this.weighingScale.serialPortOptions,
							baudRate: 9600,
							parity: 'none',
							dataBits: 8,
							stopBits: 1,
							command: ''
						}
					}
				}
			},
			async getSerialPorts () {
				this.loading = true

				const serialPorts = await this.$bridge.getSerialPorts()

				this.serialPorts = typeof serialPorts === 'string' ? JSON.parse(serialPorts) : serialPorts
				this.loading = false
			},
			testWeighingScale () {
				this.getWeight((weight) => {
					if (weight) {
						this.weight = weight
					} else {
						this.$swal({
							title: this.$t('weighingScaleError.title'),
							text: this.$t('weighingScaleError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}
				})
			}
		}
	}
