
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import merge from 'lodash.merge'

	const initialState = () => ({
		permitTypes: [
			{
				text: 'selectOption',
				value: null
			},
			{
				text: 'Daily',
				value: 'daily'
			},
			{
				text: 'Yearly',
				value: 'yearly'
			},
			{
				text: 'Lifetime',
				value: 'lifetime'
			}
		],
		customers: [],
		marketingConsent: true,
		selectedCustomer: {
			first_name: '',
			last_name: '',
			gender: '',
			dob: '',
			phone: '',
			email: '',
			code: '',
			customer_group: '',
			credit_limit: '',
			address: {
				line: '',
				area: '',
				city: '',
				state: '',
				country: '',
				zip: '',
				landmark: ''
			},
			custom_attributes: {
				company_name: '',
				trn: '',
				cr: '',
				permit_type: 'daily',
				permit_validity: '',
				marketing_consent: null,
				phone_type: 'mobile'
			}
		},
		customerGroups: []
	})

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			customerProp: {
				type: Object,
				default: null
			},
			source: {
				type: String,
				default: 'checkout'
			}
		},
		data () {
			return initialState()
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			isPermitNumberRequired () {
				return typeof this.location.customAttributes !== 'undefined' &&
					Array.isArray(this.location.customAttributes.permit_numbers) &&
					this.location.customAttributes.permit_numbers.length > 0
			},
			settings () {
				return this.$store.state.settings
			}
		},
		methods: {
			init () {
				if (this.customerProp) {
					this.selectedCustomer = merge({}, this.selectedCustomer, JSON.parse(this.objToJson(this.customerProp)))

					if (this.customerProp.custom_attributes.permit_type) {
						this.onPermitTypeChange(this.customerProp.custom_attributes.permit_type)
					}
				}
			},
			async getCustomerGroups (searchTerm) {
				if (typeof this.$bridge.getCustomerGroups === 'function') {
					const customerGroups = await this.$bridge.getCustomerGroups(this.deviceId, this.objToJson({
						merchant_id: this.merchant.id,
						search_term: searchTerm
					}))

					this.customerGroups = typeof customerGroups === 'string' ? JSON.parse(customerGroups) : customerGroups
				}
			},
			async getCustomers (searchTerm) {
				if (searchTerm) {
					const customers = await this.$store.dispatch('bridgeCall', {
						methodName: 'getCustomers',
						args: [this.deviceId, this.objToJson({ search_term: searchTerm })]
					})

					this.customers = (
						typeof customers === 'string' ? JSON.parse(customers) : customers
					)?.data || []
				} else {
					this.customers = []
				}
			},
			selectCustomer (customer) {
				this.processMarketingConsent(customer)
				this.$emit('update:customer', customer)
				this.$refs.addCustomer.hide()
			},
			onPermitTypeChange ($event) {
				if (this.customerProp) {
					this.selectedCustomer.custom_attributes.permit_number = this.customerProp.custom_attributes.permit_type === $event
						? (this.customerProp.custom_attributes.permit_number || '')
						: ''

					if ($event === 'lifetime') {
						delete this.selectedCustomer.custom_attributes.permit_validity
					} else if ($event === 'yearly' && this.customerProp.custom_attributes.permit_type !== 'yearly') {
						this.selectedCustomer.custom_attributes.permit_validity = ''
					} else if (this.customerProp.custom_attributes.permit_validity) {
						this.selectedCustomer.custom_attributes.permit_validity = this.customerProp.custom_attributes.permit_validity
					}
				}
			},
			resetSelectedCustomer () {
				this.selectedCustomer = initialState().selectedCustomer
				this.$emit('update:customer', null)
			},
			async submitForm () {
				if (await this.$refs.validator?.validate()) {
					let customer = await this.$store.dispatch('bridgeCall', {
						methodName: 'getCustomers',
						args: [this.deviceId, this.objToJson({ id: parseInt(this.selectedCustomer.phone) })]
					})

					customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

					if (customer && customer.id !== this.selectedCustomer.id) {
						if (this.$refs.saveBtn) {
							this.$refs.saveBtn.classList.remove('running')
							this.$refs.saveBtn.disabled = false
						}

						return this.$refs.validator?.setErrors({
							phone: [this.$t('mobileNumberExists')]
						})
					}

					if (this.selectedCustomer.code && this.appVersionNumber >= 4117) {
						customer = await this.$bridge.getCustomers(this.deviceId, this.objToJson({
							code: this.selectedCustomer.code
						}))
						customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

						if (customer && customer.id !== this.selectedCustomer.id) {
							if (this.$refs.saveBtn) {
								this.$refs.saveBtn.classList.remove('running')
								this.$refs.saveBtn.disabled = false
							}

							return this.$refs.validator?.setErrors({
								code: [this.$t('customerCodeExists')]
							})
						}
					}

					customer = {
						id: parseInt(this.selectedCustomer.phone),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						customer_id: this.selectedCustomer.customer_id,
						first_name: this.selectedCustomer.first_name,
						last_name: this.selectedCustomer.last_name,
						gender: this.selectedCustomer.gender,
						dob: this.selectedCustomer.dob,
						code: this.selectedCustomer.code,
						email: this.selectedCustomer.email,
						phone: this.selectedCustomer.phone,
						address: this.selectedCustomer.address,
						customer_group: this.selectedCustomer.customer_group || null,
						credit_limit: this.selectedCustomer.credit_limit || 0,
						credit: this.selectedCustomer.credit || 0,
						debit: this.selectedCustomer.debit || 0,
						custom_attributes: this.selectedCustomer.custom_attributes,
						updated_at: new Date(),
						is_active: true
					}
					this.processMarketingConsent(customer)

					if (!this.selectedCustomer.id) {
						this.selectedCustomer = { ...customer }
					}

					// eslint-disable-next-line
					if (this.selectedCustomer.id && this.selectedCustomer.id != this.selectedCustomer.phone) {
						await this.$store.dispatch('bridgeCall', {
							methodName: 'updateCustomerPhone',
							args: [this.selectedCustomer.id, customer.phone]
						})
						this.selectedCustomer.custom_attributes.alternative_phone = this.selectedCustomer.id
						this.selectedCustomer.id = customer.id
					}

					customer = {
						...customer,
						address: this.objToJson(customer.address),
						custom_attributes: this.objToJson(customer.custom_attributes)
					}
					this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Customer',
							this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
							true
						]
					})
					this.selectedCustomer.custom_attributes.new_customer = true
					this.$emit('update:customer', this.selectedCustomer)
					this.$refs.addCustomer.hide()
				}
			},
			processMarketingConsent (customer) {
				if (this.marketingConsent && this.settings.general.enable_marketing_consent) {
					customer.custom_attributes.marketing_consent = { verified_on: this.$moment.utc(new Date()).format('YYYY-MM-DD HH:mm') }
				} else {
					customer.custom_attributes.marketing_consent = null
				}

				return customer
			},
			cleanUp () {
				if (this.$refs.searchTerm) {
					this.$refs.searchTerm.value = ''
				}

				Object.assign(this.$data, initialState())

				if (this.source === 'customer') {
					this.$emit('update:customer', this.selectedCustomer)
				}
			}
		}
	}
