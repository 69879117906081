
	let eventTimes = []
	let tempBarcode = ''

	export default {
		data () {
			return {
				loading: false,
				serialPorts: [],
				barcode: '_ _ _ _ _ _ _ _ _ _ _ _ _ _'
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			baudRates () {
				return this.$store.state.baudRates
			},
			scanner: {
				get () {
					return this.$store.state.scanner
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'scanner',
						value,
						save: true
					})
				}
			}
		},
		watch: {
			scanner: {
				handler (value) {
					this.scanner = value
					setTimeout(() => {
						if (value?.serialPortOptions?.path && typeof this.$bridge.initScannerSerialPort === 'function') {
							this.$bridge.initScannerSerialPort()
						}
					}, 1000)
				},
				deep: true
			}
		},
		mounted () {
			document.addEventListener('keydown', this.readBarcode)
		},
		destroyed () {
			document.removeEventListener('keydown', this.readBarcode)
		},
		methods: {
			setHardware ($event) {
				if ($event === 'magellan-9800i') {
					this.scanner = {
						...this.scanner,
						hardware: $event,
						serialPortOptions: {
							...this.scanner.serialPortOptions,
							baudRate: 9600,
							parity: 'none',
							dataBits: 8,
							stopBits: 1,
							command: ''
						}
					}
				} else {
					this.scanner = {
						...this.scanner,
						hardware: $event,
						serialPortOptions: {}
					}
				}
			},
			async getSerialPorts () {
				this.loading = true

				const serialPorts = await this.$bridge.getSerialPorts()

				this.serialPorts = typeof serialPorts === 'string' ? JSON.parse(serialPorts) : serialPorts
				this.loading = false
			},
			readBarcode (e) {
				eventTimes.push(new Date().valueOf())

				if (!document.querySelector('input:focus')) {
					const textInput = e.key || String.fromCharCode(e.keyCode)

					if (tempBarcode && textInput === 'Enter') {
						this.barcode = this.getBarcode()
						tempBarcode = ''
						eventTimes = []
					} else {
						tempBarcode += textInput
					}
				}
			},
			getBarcode () {
				for (let i = 0; i < eventTimes.length; i++) {
					if (eventTimes[i + 1] && eventTimes[i + 1] - eventTimes[i] > 20) {
						tempBarcode = tempBarcode.slice(1)
					}
				}

				return tempBarcode
			}
		}
	}
