
	export default {
		props: {
			tableOrder: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		data () {
			return {
				floors: {},
				tableOrders: [],
				fields: [
					{
						key: 'receipt_code',
						label: 'bill number',
						tdClass: 'align-middle'
					}, {
						key: 'tables',
						label: 'table',
						pluralizationIndex: 2,
						tdClass: 'align-middle'
					}, {
						key: 'customer',
						label: 'customer',
						tdClass: 'align-middle'
					}, {
						key: 'total_price',
						label: 'total',
						tdClass: 'align-middle'
					}, {
						key: 'created_at',
						label: 'created at',
						tdClass: 'align-middle'
					}, {
						key: 'selected',
						label: 'selected',
						tdClass: 'align-middle text-center'
					}
				],
				isEditOrder: null,
				selectedOrders: [],
				disableDone: false,
				showError: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			selectedTables: {
				get () {
					return this.$store.state.selectedTables
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedTables',
						value
					})
				}
			},
			selectedOrder: {
				get () {
					return this.$store.state.selectedOrder
				},
				set (order) {
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: order
					})
				}
			}
		},
		methods: {
			init () {
				this.disableDone = false
				this.selectedOrders = []
				this.getTables()
				this.getTableOrders()
			},
			async getTables () {
				let tables = await this.$store.dispatch('bridgeCall', {
					methodName: 'getTables',
					args: [this.deviceId, this.locationId, this.objToJson({
						offset: -1
					})]
				})

				tables = typeof tables === 'string' ? JSON.parse(tables) : tables
				tables = tables.data || tables
				this.floors = this.groupBy(tables.map((t) => {
					return {
						...t,
						floor_name: t.floor.name
					}
				}), 'floor_name')
			},
			selectTable (table) {
				const tableIndex = this.selectedTables.findIndex(t => t.id === table.id)

				if (tableIndex !== -1) {
					this.selectedTables.splice(tableIndex, 1)
				} else {
					this.selectedTables.push(table)
				}

				this.showError = null
				this.getTableOrders()
			},
			async getTableOrders () {
				if (this.selectedTables.length) {
					const tableOrders = await this.$store.dispatch('bridgeCall', {
						methodName: 'getOrders',
						args: [this.deviceId, this.objToJson({
							table_id: this.selectedTables.map(table => table.id),
							status: ['pending', 'billed']
						})]
					})

					this.tableOrders = (
						typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders
					).data
				} else {
					this.tableOrders = []
				}

				if (this.tableOrders.length && this.selectedOrder) {
					this.tableOrders = this.tableOrders.filter(to => to.id !== this.selectedOrder.id)

					setTimeout(() => {
						const orderIndex = this.tableOrders.findIndex(o => o.id === this.selectedOrder.id)

						if (orderIndex !== -1 && this.$refs.ordersTable) {
							this.$refs.ordersTable.selectRow(orderIndex)
						}
					}, 0)
				}
			},
			selectOrder (orders) {
				this.showError = null
				this.selectedOrders = [...orders]
			},
			processMergeOrder () {
				this.disableDone = true

				if (this.selectedTables.length && this.selectedOrders.length) {
					if (this.selectedOrders.every(order => order.price_category?.id === this.tableOrder.price_category?.id)) {
						this.$emit('update:processMergeOrder', this.selectedOrders)
					} else {
						this.showError = this.$t('orderSelectMandatoryError.text3')
					}
				} else if (this.selectedTables.length < 1) {
					this.showError = this.$t('tableSelectMandatoryError.text2')
				} else if (this.selectedOrders.length < 1) {
					this.showError = this.$t('orderSelectMandatoryError.text2')
				}

				this.disableDone = false
			},
			closeOrdersModal () {
				this.showError = null
				this.selectedOrder = null
				this.selectedOrders = []
				this.selectedTables = []
				this.$bvModal.hide('orders-modal')
			}
		}
	}
