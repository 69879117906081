
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				tables: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedTable: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'floor_name',
						label: 'floor name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'seat_count',
						label: 'seating capacity',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					},
					{
						key: 'status',
						label: 'status',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'price_category_id',
						label: 'price category',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				],
				tableName: null,
				seatCount: null,
				selectedFloor: null,
				floors: [],
				priceCategories: [],
				priceCategory: null
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getTables()
			await this.getPriceCategories()
			await this.getFloors()
		},
		methods: {
			async getTables (isLoadMore) {
				let data = await this.$bridge.getTables(this.deviceId, this.locationId, this.objToJson({
					offset: isLoadMore ? this.tables.offset + 20 : 0
				}))

				data = (typeof data === 'string' ? JSON.parse(data) : data)

				this.tables.data = data.offset ? this.taxes.data.concat(data.data) : data.data || data
				this.tables.offset = data.offset || 0
				this.tables.total = data.total || data.data.length
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getTables(true)
				}
			},
			async getPriceCategories () {
				this.priceCategories = []

				let priceCategories = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					type: 'base'
				}))

				priceCategories = (typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories)

				this.priceCategories = priceCategories.data ? priceCategories.data : priceCategories
			},
			async getFloors () {
				this.floors = []

				this.floors = await this.$bridge.getFloors(this.deviceId, this.locationId, this.objToJson({
					merchant_id: this.merchant.id
				}))

				this.floors = (typeof data === 'string' ? JSON.parse(this.floors) : this.floors)

				this.floors = this.floors.data || this.floors
			},
			createTable () {
				this.selectedTable = null
				this.$refs.tableModal.show()
			},
			editTable (table) {
				this.selectedTable = table
				this.$refs.tableModal.show()
			},
			deleteTable (table) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						table = {
							id: table.id,
							is_active: false,
							updated_at: new Date()
						}

						await this.$bridge.insert(
							'Table',
							this.bridgeName === 'ANDROID' ? this.objToJson(table) : table,
							true
						)
						this.getTables()
					}
				})
			},
			initModal () {
				if (this.selectedTable) {
					this.tableName = this.selectedTable.name
					this.seatCount = this.selectedTable.seat_count
					this.selectedFloor = this.selectedTable.floor
					this.priceCategory = this.priceCategories.find(pc => pc.id === this.selectedTable.price_category_id)
				}
			},
			async createOrUpdateTable () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()

					let selectedFloor = await this.$bridge.getFloors(this.deviceId, this.locationId, this.objToJson({
						merchant_id: this.merchant.id,
						id: this.selectedFloor.id
					}))

					selectedFloor = (typeof selectedFloor === 'string' ? JSON.parse(selectedFloor) : selectedFloor)
					selectedFloor = selectedFloor.data || selectedFloor

					let selectedFloorTables = selectedFloor[0].tables.filter(t => t.id !== this.selectedTable?.id)

					selectedFloorTables = selectedFloorTables.concat(
						{
							id: this.selectedTable?.id || +date.valueOf(),
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							location_id: this.locationId,
							floor_id: this.selectedFloor.id,
							name: this.tableName,
							price_category_id: this.priceCategory?.id || null,
							seat_count: +this.seatCount,
							status: this.selectedTable?.status || 'free',
							layout: this.selectedTable?.layout || {},
							custom_attributes: this.selectedTable?.custom_attributes || {},
							updated_at: date
						}
					)
					const floor = {
						id: this.selectedFloor.id,
						tables: selectedFloorTables.map(t => ({
							...t,
							layout: this.objToJson(t.layout),
							custom_attributes: this.objToJson(t.custom_attributes)
						}))
					}

					await this.$bridge.insert(
						'Floor',
						this.bridgeName === 'ANDROID' ? this.objToJson(floor) : floor,
						true
					)

					this.$refs.tableModal.hide()
					this.getTables()
				}
			},
			resetModal () {
				this.tableName = null
				this.selectedTable = null
				this.seatCount = null
				this.priceCategory = null
				this.selectedFloor = null
			}
		}
	}
