
	import { ValidationObserver } from 'vee-validate'

	export default {
		components: {
			ValidationObserver
		},
		data () {
			return {
				keystrokes: '',
				tempDiscount: null,
				selectedCartItemIndex: -1,
				dynamicDiscountType: null,
				loading: false,
				giftCardCode: ''
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			settings () {
				return this.$store.state.settings
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			cart () {
				return this.$store.state.cart
			},
			onHold () {
				return this.$store.state.onHold
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			dynamicDiscount () {
				return this.$store.getters.dynamicDiscount
			},
			showOrderDiscounts () {
				return this.$store.getters.showOrderDiscounts
			},
			computedPrice () {
				const price = {
					subtotal: this.cart.price.subtotal,
					tax: this.cart.price.tax
				}

				if (
					this.settings.general.tax_calculation_phase === 'before_discount' &&
					this.settings.general.include_tax_in_subtotal
				) {
					price.subtotal -= this.cart.price.discountedAmount
					price.tax -= this.cart.price.discountedTax
				}

				return price
			},
			tip: {
				get () {
					return this.$store.state.tip
				},
				set (value) {
					this.$store.commit('setState', { key: 'tip', value })
				}
			},
			selectedOrder: {
				get () {
					return this.$store.state.selectedOrder
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedOrder', value })
				}
			},
			checkout: {
				get () {
					return this.$store.state.checkout
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'checkout',
						value
					})
				}
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			kotCancellationModal: {
				get () {
					return this.$store.state.kotCancellationModal
				},
				set (value) {
					this.$store.commit('setState', { key: 'kotCancellationModal', value })
				}
			},
			isGiftCardDiscountApplied () {
				return this.$store.state.cart.items.find(item => item.discounts.find(d => d.entity === 'gift_card'))
			}
		},
		watch: {
			cart (cart, prevCart) {
				if (prevCart.items.length !== 0 && cart.items.length === 0) {
					cart.additionalInfo = {}
					cart.table = null
					cart.charges = []
					cart.ignoredCharges = []
					this.tip = null
					this.selectedOrder = null
					this.selectedTables = []
					cart.selectedDiscount = null
				}

				if (
					cart.selectedDiscount?.apply_discount_sub_total &&
					!cart.selectedDiscount?.is_automatic &&
					cart.selectedDiscount?.discount_items?.length === 0 &&
					this.cart.price.subtotal < cart.selectedDiscount?.buy_condition_value
				) {
					this.setSelectedDiscount(null)
				}
			}
		},
		mounted () {
			this.$root.$on('bv::dropdown::show', (bvEvent) => {
				if (bvEvent.componentId === 'dropdown-2') {
					this.isDropdown2Visible = true
				}
			})
			this.$root.$on('bv::dropdown::hide', (bvEvent) => {
				if (bvEvent.componentId === 'dropdown-2') {
					this.isDropdown2Visible = false
				}

				if (this.isDropdown2Visible) {
					bvEvent.preventDefault()
				}
			})
			document.addEventListener('keydown', this.keyboardEvents)
			this.$root.$on('discount-verified', () => this.setSelectedDiscount(this.tempDiscount))
			this.$root.$on('gift-card-verified', this.processGiftCardDiscount)
			this.$root.$on('remove-charge-verified', this.removedCharges)
		},
		destroyed () {
			document.removeEventListener('keydown', this.keyboardEvents)
			this.$root.$off('discount-verified')
			this.$root.$off('gift-card-verified', this.processGiftCardDiscount)
			this.$root.$off('remove-charge-verified', this.removedCharges)
		},
		methods: {
			async getItemVariation (variation, index) {
				if (!variation.custom_attributes.gift_card) {
					let item = await this.$bridge.getItems(
						this.deviceId, this.locationId, this.objToJson({
							variation_id: variation.id,
							price_category_id: this.cart.priceCategory?.id
						})
					)

					item = (typeof item === 'string' ? JSON.parse(item) : item).data[0]

					if (item) {
						item.show = true
						item.variations[0].cart_index = index
						item.variations[0].price = variation.price
						item.variations[0].batch_id = variation.batch_id
						item.variations[0].quantity = variation.quantity
						item.variations[0].groups = variation.groups
						item.variations[0].item_discount = this.cart.items[index]?.item_discount || []

						if (variation.itemization_type === 'custom_amount') {
							item.name = variation.item_name
							item.variations[0].name = variation.name
							item.variations[0].pricing_type = 'variable'
						}

						this.$store.commit('setState', {
							key: 'selectedItem',
							value: item
						})
					}
				}
			},
			showModal (index, id, type) {
				if (type === 'item') {
					this.selectedCartItemIndex = index
				}

				this.dynamicDiscountType = type
				this.$nextTick(() => this.$bvModal.show(id))
			},
			removeDynamicDiscount (index) {
				this.cart.items[index].item_discount.pop(this.cart.items[index].item_discount.findIndex(i => i.id === this.dynamicDiscount.id))
				this.cart.items[index].discounts.pop(this.cart.items[index].discounts.findIndex(i => i.id === this.dynamicDiscount.id))
				const cartItems = [...this.cart.items]
				const newCartItems = cartItems.reduce((items, item) => {
					const index = items.findIndex((i) => {
						const iDynamicDiscount = i.item_discount && i.item_discount.find(i => i.id === this.dynamicDiscount.id)
						const itemDynamicDiscount = item.item_discount && item.item_discount.find(i => i.id === this.dynamicDiscount.id)
						const dynamicDiscountFlag = (!itemDynamicDiscount && !iDynamicDiscount && i.id === item.id) || (iDynamicDiscount && itemDynamicDiscount && iDynamicDiscount.get_discount_type === itemDynamicDiscount.get_discount_type &&
							iDynamicDiscount.get_discount_value === itemDynamicDiscount.get_discount_value)

						return dynamicDiscountFlag
					})

					index === -1 ? items.push(item) : items[index].quantity += item.quantity

					return items
				}, [])

				this.$store.commit('setCart', { items: newCartItems })
				this.$store.dispatch('cartCalculation')
			},
			kotCancellationCheck (index) {
				if (['restaurant', 'qsr'].includes(this.merchant.businessType)) {
					const selectedOrderItemIndex = this.selectedOrder
						? this.filterItem(this.selectedOrder.items, this.cart.items[index], 'index')
						: -1

					if (this.selectedOrder && selectedOrderItemIndex >= 0) {
						window.kotCancellationModalCallback = this.kotCancellationModalCallback
						this.kotCancellationModal = {
							show: true,
							data: {
								item: this.selectedOrder.items[selectedOrderItemIndex],
								index
							}
						}
					} else {
						this.removeCartItem(index)
					}
				} else {
					this.removeCartItem(index)
				}
			},
			kotCancellationModalCallback () {
				this.removeCartItem(this.kotCancellationModal.data.index)
				this.kotCancellationModal = {
					show: false,
					data: {}
				}
			},
			removeCartItem (index) {
				const cartItem = JSON.parse(this.objToJson(this.cart.items))[index]

				this.$store.dispatch('modifyCart', {
					item: {
						id: cartItem.item_id,
						name: cartItem.item_name,
						category_id: cartItem.category_id,
						brand_id: cartItem.brand_id
					},
					variation: {
						...cartItem,
						quantity: 0
					}
				})
			},
			updateSelectedDiscount ($event) {
				if (!this.employeePolicy.isAdmin && +$event?.custom_attributes.is_totp_required) {
					this.tempDiscount = $event
					this.otpModalType = 'discount'
				} else {
					this.setSelectedDiscount($event)
				}
			},
			setSelectedDiscount (discount) {
				this.$store.commit('setCart', { selectedDiscount: discount })
				this.$store.dispatch('cartCalculation')
				this.tempDiscount = null
			},
			removeCharges () {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('removeCharges'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then((response) => {
					if (response) {
						if (this.employeePolicy.isAdmin || this.employeePolicy.cart?.includes('remove_charge')) {
							this.removedCharges()
						} else {
							this.otpModalType = 'remove-charge'
						}
					}
				})
			},
			removedCharges () {
				this.$store.commit('setCart', {
					charges: [],
					ignoredCharges: [...new Set(this.cart.price.charges.map(c => c.id).concat(this.cart.ignoredCharges))]
				})
				this.$store.dispatch('cartCalculation')
			},
			emptyCart () {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('emptyCart'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then((response) => {
					if (response) {
						this.$store.commit('resetCart')
					}
				})
			},
			holdCart () {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('holdCart'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then((response) => {
					if (response) {
						this.$store.dispatch('holdCart')
					}
				})
			},
			async initCheckout ($event) {
				if ($event.detail <= 1) {
					const requiredLabel = this.cart.priceCategory?.custom_attributes?.labels
						? Object.values(this.cart.priceCategory.custom_attributes.labels).find((label) => {
							return label.is_required === 'true' && !this.cart.additionalInfo[label.name.toLowerCase().split(' ').join('-')]
						})
						: null

					if (requiredLabel) {
						return this.$bvModal.show('additional-info')
					}

					if (this.cart.table || this.cart.orderType === 'drive_thru' || this.cart.storeOrder) {
						if (this.selectedOrder && this.cart.table) {
							let dbOrder = await this.$store.dispatch('bridgeCall', {
								methodName: 'getOrders',
								args: [this.deviceId, this.objToJson({
									id: this.selectedOrder.id
								})]
							})

							dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

							if (this.$moment(this.selectedOrder.updated_at).diff(this.$moment(dbOrder.updated_at), 'seconds') !== 0) {
								return this.$swal({
									title: this.$t('tableOrderupdateAlert.title'),
									text: this.$t('tableOrderupdateAlert.text'),
									icon: 'warning',
									buttons: this.$t('ok'),
									dangerMode: true
								}).then((response) => {
									if (response) {
										this.$emit('update:table')
									}
								})
							}
						}

						this.checkout = { show: true, isTrusted: false }

						setTimeout(() => {
							const btn = document.getElementById('confirm-wo-print')

							if (btn) {
								btn.click()
							}
						}, 500)
					} else {
						this.checkout = { show: true, isTrusted: $event.isTrusted }
					}
				}
			},
			keyboardEvents (e) {
				const now = Date.now()

				if ((now - this.keystrokeDiff) > 1500) {
					this.keystrokes = ''
				}

				this.keystrokeDiff = now

				if (document.querySelectorAll('.modal.show, .swal-overlay--show-modal').length === 0) {
					if (e.key === 'Escape') {
						this.keystrokes = ''

						return
					}

					if (e.key === 'Backspace') {
						this.keystrokes = this.keystrokes.slice(0, -1)

						return
					}

					if (![
						'Enter', 'Shift', 'Delete', 'CapsLock'
					].includes(e.key)) {
						this.keystrokes += e.key
					}

					if (this.keystrokes.includes('Tab')) {
						this.keystrokes = ''

						return
					}

					if (this.cart.items.length) {
						const keyPressed = e.code.replace(/Key/g, '')

						if ((/^F(1[0-2]?|[2-9])$/.test(keyPressed) || /^Alt/.test(this.keystrokes)) && keyPressed === this.keyboardShortcuts.checkout) {
							this.initCheckout({ detail: 1, isTrusted: true })
							this.keystrokes = ''
						} else if (!this.checkout.show && (/^F(1[0-2]?|[2-9])$/.test(keyPressed) || /^Alt/.test(this.keystrokes)) && keyPressed === this.keyboardShortcuts.cash_order_confirm) {
							this.checkout = { show: true, isTrusted: true }
							this.keystrokes = ''

							return setTimeout(() => {
								const btn = document.getElementById('confirm')

								if (btn) {
									btn.click()
								}
							}, 100)
						}
					}

					const selectedCartItem = document.activeElement?.getAttribute('class')?.includes('cart-item') ? document.activeElement : null

					if (selectedCartItem && this.cart.items.length && ['ArrowUp', 'ArrowDown'].includes(e.key) &&
						!document.querySelector('.modal.show')) {
						let cartItem
						const cartItems = document.querySelectorAll('.cart-item')

						if (selectedCartItem) {
							cartItem = e.key === 'ArrowUp' ? selectedCartItem.previousSibling : selectedCartItem.nextSibling

							if (!cartItem) {
								cartItem = e.key === 'ArrowUp' ? cartItems[cartItems.length - 1] : cartItems[0]
							}
						} else {
							cartItem = document.querySelector('.cart-item')
						}

						cartItem.focus()
						document.querySelector('.cart-items').scrollTop = cartItem.offsetTop
						this.keystrokes = ''
					} else if (e.key === 'Enter') {
						if (selectedCartItem &&
							this.keystrokes[0] === this.keyboardShortcuts.quantity_delimiter) {
							const cart = Object.assign({}, this.cart)
							const cartItems = Array.prototype.slice
								.call(document.querySelector('.cart-items').children)
							const quantity = parseFloat(
								this.keystrokes.split(this.keyboardShortcuts.quantity_delimiter).pop()
							)

							if (quantity > 0) { cart.items[cartItems.indexOf(selectedCartItem)].quantity = quantity } else { cart.items.splice(cartItems.indexOf(selectedCartItem), 1) }

							this.$store.commit('setCart', cart)
							this.$store.dispatch('cartCalculation')
							this.keystrokes = ''
						} else if (!selectedCartItem && e.key === 'Enter') {
							this.keystrokes = ''
						} else if (selectedCartItem && !this.keystrokes) {
							selectedCartItem.click()
						}

						this.keystrokes = ''
					} else if (selectedCartItem && e.key === 'Delete') {
						this.keystrokes = ''
						selectedCartItem.querySelector('.btn-danger').click()
					}
				}
			},
			async applyGiftCardDiscount () {
				if (await this.$refs.giftCardValidator.validate()) {
					if (this.employeePolicy.isAdmin || (this.employeePolicy.cart && this.employeePolicy.cart.includes('apply_gift_card'))) {
						this.processGiftCardDiscount()
					} else {
						this.otpModalType = 'gift-card'
					}
				}
			},
			async processGiftCardDiscount () {
				this.loading = true

				try {
					const response = await this.$store.dispatch('validateGiftCard', {
						code: this.giftCardCode.slice(0, 5) + '-' + this.giftCardCode.slice(5, 10) + '-' + this.giftCardCode.slice(10, 15)
					})

					if (response.data.data.balance) {
						const giftCardDiscount = response.data.data.discount

						this.cart.selectedDiscount = {
							id: giftCardDiscount.id,
							name: giftCardDiscount.name,
							description: giftCardDiscount.description,
							get_discount_type: giftCardDiscount.getDiscountType,
							get_discount_value: response.data.data.balance,
							entity: giftCardDiscount.discountEntity.short_name,
							apply_discount_sub_total: giftCardDiscount.applyDiscountSubTotal,
							is_automatic: giftCardDiscount.isAutomatic,
							code: giftCardDiscount.code,
							expires_on: giftCardDiscount.expiresOn,
							max_discount_value: response.data.data.balance
						}

						this.$store.dispatch('cartCalculation')
						this.$refs.giftModal.hide()
					} else {
						this.$refs.giftCardValidator.setErrors({
							'gift card code': this.$t('balance zero')
						})
					}
				} catch (err) {
					this.$refs.giftCardValidator.setErrors({
						'gift card code': err.response?.data?.data?.message || this.$t('invalid code')
					})
				}

				this.loading = false
			}
		}
	}
