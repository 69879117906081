
	let debounceTimer = null

	export default {
		data () {
			return {
				loading: {
					orders: true,
					order: false
				},
				processingOrder: false,
				orders: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedOrder: {},
				filters: {
					searchTerm: '',
					status: null
				},
				customers: [],
				orderStatus: [
					{
						text: 'pending',
						value: 'pending'
					},
					{
						text: 'billed',
						value: 'billed'
					}
				],
				showOrderModal: false,
				waiters: [],
				selectedWaiter: null,
				vehicleNumber: ''
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			settings () {
				return this.$store.state.settings
			},
			cart () {
				return this.$store.state.cart
			},
			checkout () {
				return this.$store.state.checkout
			},
			tip: {
				get () {
					return this.$store.state.tip
				},
				set (value) {
					this.$store.commit('setState', { key: 'tip', value })
				}
			},
			orderFilters () {
				return {
					drive_thru: true,
					search_term: this.filters.searchTerm,
					status: this.filters.status || ['pending', 'billed'],
					sort_by: 'created_at ASC',
					limit: this.paginationSize
				}
			},
			computedPrice () {
				const price = {
					subtotal: this.selectedOrder.sub_total || 0,
					tax: this.selectedOrder.total_tax || 0
				}

				if (
					this.selectedOrder.id && (
						!this.selectedOrder.custom_attributes.tax_calculation_phase ||
						this.selectedOrder.custom_attributes.tax_calculation_phase === 'before_discount'
					) && this.settings.general.include_tax_in_subtotal
				) {
					price.subtotal -= this.selectedOrder.total_discounted_amount
					price.tax -= this.selectedOrder.total_discounted_tax
				}

				return price
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			},
			paginationSize () {
				return this.$store.state.paginationSize
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			}
		},
		watch: {
			checkout (checkout) {
				if (!checkout.show) {
					this.processingOrder = false
					this.getOrders({})
				}
			}
		},
		async beforeMount () {
			await this.getOrders({})
			this.loading.orders = false
			requestAnimationFrame(() => {
				this.$refs.ordersBlock?.addEventListener('scroll', this.loadMoreOrders)
			})
		},
		mounted () {
			this.$root.$on('order-update', this.searchOrders)
			this.$root.$on('update-order', this.updateOrder)
		},
		destroyed () {
			this.$root.$off('order-update', this.searchOrders)
			this.$root.$on('update-order', this.updateOrder)
		},
		methods: {
			async getOrders ({ offset }) {
				let orders = await this.$store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [this.deviceId, this.objToJson({
						...this.orderFilters,
						offset
					})]
				})

				orders = typeof orders === 'string' ? JSON.parse(orders) : orders

				if (offset) {
					orders.data = this.orders.data.concat(orders.data)
				}

				this.orders = orders

				if (this.selectedOrder?.id) {
					this.selectedOrder = this.orders.data.find(o => o.id === this.selectedOrder.id) || {}
				}
			},
			searchOrders () {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getOrders({})
				}, 500)
			},
			loadMoreOrders () {
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					this.getOrders({ offset: this.orders.offset + (this.appVersionNumber >= 4024 ? this.paginationSize : 50) })
				}, 100)
			},
			clearFilters () {
				this.filters = {
					searchTerm: '',
					customer: null,
					paymentMethodId: null,
					status: null
				}
				this.dateRange = { startDate: null, endDate: null }
				this.getOrders({})
			},
			async getWaiters () {
				let waiters = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
					type: 'waiter',
					offset: -1
				}))

				waiters = (typeof waiters === 'string' ? JSON.parse(waiters) : waiters)
				this.waiters = waiters.data || waiters
				setTimeout(() => {
					document.querySelector('#order-modal .vs__search')?.focus()
				}, 500)
			},
			initNewOrder () {
				this.showOrderModal = false
				this.$store.commit('resetCart')
				this.$store.commit('setCart', {
					orderType: 'drive_thru',
					waiter: this.selectedWaiter,
					vehicleNumber: this.vehicleNumber
				})
				this.$emit('update:sell')
			},
			resetOrderModal () {
				this.selectedWaiter = null
				this.vehicleNumber = ''
			},
			initOrderEdit () {
				if (this.selectedOrder.status === 'billed') {
					this.employeePolicy.isAdmin ? this.editOrder() : (this.otpModalType = 'edit-billed-order')
				} else {
					this.editOrder()
				}
			},
			editOrder () {
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: this.selectedOrder
				})

				if (this.selectedOrder.discounts?.length) {
					this.cart.selectedDiscount = this.selectedOrder.discounts[0]
				}

				this.addOrderItemsToCart(this.selectedOrder)
				this.$emit('update:sell')
			},
			async printReceipt () {
				const order = {
					id: this.selectedOrder.id,
					status: 'billed',
					updated_at: new Date()
				}

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})
				window.printOrder({
					...this.selectedOrder,
					customers: this.selectedOrder.customer ? [this.selectedOrder.customer] : [],
					status: 'billed',
					reprint: true
				})
				this.getOrders({})
			},
			cancelConfirmation () {
				if (!this.isOnline &&
					this.selectedOrder?.custom_attributes?.order_type === 'dine_in' &&
					this.selectedOrder?.custom_attributes?.platform === 'online'
				) {
					return this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				} else {
					this.$swal({
						title: this.$t('areYouSure'),
						text: this.$t('cancelOrderWarning'),
						icon: 'warning',
						buttons: [this.$t('no'), this.$t('yes')],
						dangerMode: true
					}).then((response) => {
						if (response) {
							if (this.employeePolicy.isAdmin || (this.employeePolicy.drive_thru && this.employeePolicy.drive_thru.includes('cancel order'))) {
								this.cancelOrder()
							} else {
								this.otpModalType = 'cancel-order'
							}
						}
					})
				}
			},
			async cancelOrder () {
				const date = new Date()
				const order = {
					id: this.selectedOrder.id,
					price_category: this.selectedOrder.price_category,
					status: 'cancelled',
					receipt_code: this.selectedOrder.receipt_code,
					ref_code: this.selectedOrder.ref_code,
					kot_items: this.selectedOrder.kot_items.map(i => ({
						...i,
						unit_measure_type: typeof i.unit_measure_type === 'object'
							? this.objToJson(i.unit_measure_type)
							: i.unit_measure_type,
						modifiers: this.objToJson(i.modifiers),
						status: 'cancelled',
						status_history: this.objToJson(i.status_history.concat([
							{
								status: 'cancelled',
								created_at: date
							}
						])),
						updated_at: date
					})),
					updated_at: date,
					is_synced: false
				}

				if (order.kot_items.length && [
					'restaurant', 'qsr'
				].includes(this.merchant.businessType)) {
					const kotItems = this.selectedOrder.kot_items.reduce((items, item) => {
						if (!['dispatched', 'cancelled', 'removed'].includes(item.status)) {
							items.push({
								...item,
								status: 'cancelled'
							})
						}

						return items
					}, [])

					if (kotItems.length) {
						window.printKot({
							...order,
							items: this.groupBy(kotItems, 'kot_device_id'),
							is_running_order: true
						})
					}
				}

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})

				if (!this.isMiniPlan) {
					const syncData = {
						id: this.getUniqueId(+order.id + 1),
						model_id: order.id,
						model_name: 'order',
						payload: this.objToJson({
							model_id: this.selectedOrder.id,
							merchant_id: this.selectedOrder.merchant_id,
							device_id: this.selectedOrder.device_id,
							location_id: this.selectedOrder.location_id,
							employee_id: this.selectedOrder.employee?.id || this.selectedOrder.employee_id || null,
							orders: [{
								id: this.selectedOrder.id,
								merchant_id: this.selectedOrder.merchant_id,
								device_id: this.selectedOrder.device_id,
								location_id: this.selectedOrder.location_id,
								employee_id: this.selectedOrder.employee?.id || this.selectedOrder.employee_id || null,
								created_by: this.selectedOrder.created_by?.id || null,
								employee_shift_id: this.selectedOrder.employee_shift_id,
								price_category_id: this.selectedOrder.price_category?.id || null,
								payment_method: this.selectedOrder.payment_method?.slug || 'cash',
								reservations_id: null,
								customer_id: this.selectedOrder.customer?.id || null,
								order_id: this.selectedOrder.order_id,
								channel: this.selectedOrder.channel,
								kot_items: order.kot_items.map(i => ({
									...i,
									unit_measure_type: (typeof i.unit_measure_type === 'string' && i.unit_measure_type.startsWith('{'))
										? JSON.parse(i.unit_measure_type)
										: i.unit_measure_type,
									modifiers: JSON.parse(i.modifiers),
									status: 'cancelled',
									status_history: typeof i.unit_measure_type === 'string'
										? JSON.parse(i.status_history)
										: i.status_history,
									updated_at: date
								})),
								items: this.selectedOrder.items.map(i => ({
									...i,
									item_inventory_id: i.inventory_id,
									item_variation_id: i.variation_id,
									item_variation_name: i.variation_name,
									single_quantity_amount: i.price,
									net_sales: i.sub_total - i.discounted_amount,
									gross_sales: i.sub_total,
									total: i.sub_total + i.tax - i.discount,
									groups: i.groups,
									tax_details: i.taxes,
									discount_details: i.discounts,
									discounts: i.discounts,
									taxes: i.taxes,
									item_discount: i.item_discount,
									unit_measure_type: i.unit_measure_type
								})),
								payment_methods: this.selectedOrder.payment_methods,
								status: 'cancelled',
								receipt_code: this.selectedOrder.receipt_code,
								ref_code: this.selectedOrder.ref_code,
								sub_total: this.selectedOrder.sub_total,
								total_tax: this.selectedOrder.total_tax,
								total_charge: this.selectedOrder.total_charge,
								total_price: this.selectedOrder.total_price,
								total_discount: this.selectedOrder.total_discount,
								total_discounted_amount: this.selectedOrder.total_discounted_amount,
								total_discounted_tax: this.selectedOrder.total_discounted_tax,
								payment_note: this.selectedOrder.payment_note,
								amount_balance_returned: this.selectedOrder.amount_balance_returned,
								total_amount: this.selectedOrder.total_amount,
								tip: this.selectedOrder.tip,
								round_off_amount: this.selectedOrder.round_off_amount,
								charges: this.selectedOrder.charges,
								taxes: this.selectedOrder.taxes,
								discounts: this.selectedOrder.discounts,
								card_details: this.selectedOrder.card_details,
								custom_attributes: this.selectedOrder.custom_attributes,
								created_at: this.selectedOrder.created_at,
								updated_at: date,
								is_synced: false
							}]
						})
					}

					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Sync',
							this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
							true
						]
					})
				}

				this.getOrders({})
			},
			completeOrder () {
				clearTimeout(this.timer)
				this.timer = setTimeout(async () => {
					this.processingOrder = true
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: this.selectedOrder
					})
					await this.addOrderItemsToCart(this.selectedOrder)

					if (this.cart.items.length !== this.selectedOrder.items.length) {
						const missingItems = this.selectedOrder.items.reduce((items, item) => {
							if (this.cart.items.findIndex(i => i.id === item.variation_id) === -1) {
								items.push(item.variation_name)
							}

							return items
						}, [])

						return this.$swal({
							title: this.$t('tableItemMissingError.title', [
								missingItems.length,
								this.$tc('item', missingItems.length > 1 ? 2 : 1)
							]),
							text: this.$t('tableItemMissingError.text', [
								missingItems.join(', '),
								this.$t(missingItems.length > 1 ? 'are' : 'is')
							]),
							icon: 'error',
							button: this.$t('ok')
						})
					}

					this.$store.commit('setState', {
						key: 'checkout',
						value: {
							show: true,
							isTrusted: true
						}
					})
				}, 500)
			},
			removeTip () {
				this.tip = null
				this.$store.dispatch('cartCalculation')
				this.updateOrder()
			},
			updateOrder () {
				this.selectedOrder = {
					...this.selectedOrder,
					sub_total: this.cart.price.subtotal,
					total_tax: this.cart.price.tax,
					taxes: this.cart.price.taxes,
					total_discount: this.cart.price.discount,
					discounts: this.cart.selectedDiscount ? [this.cart.selectedDiscount] : [],
					total_charge: this.cart.price.charge,
					charges: this.cart.price.charges,
					tip: this.cart.price.tip,
					total_price: this.cart.price.total,
					custom_attributes: {
						...this.selectedOrder.custom_attributes,
						tip: this.tip
					}
				}
			}
		}
	}
