
	export default {
		data () {
			return {
				taxes: {
					data: [],
					offset: 0,
					total: 0
				},
				showTax: false,
				selectedTax: null,
				fields: [
					{
						key: 'name',
						label: 'tax name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'rate',
						label: 'percentage',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					},
					{
						key: 'inclusion_type',
						label: 'type',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			taxCreationModal: {
				get () {
					return this.$store.state.taxCreationModal
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'taxCreationModal',
						value
					})
				}
			}
		},
		async beforeMount () {
			await this.getTaxes()

			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}
		},
		methods: {
			async getTaxes (isLoadMore) {
				let data = await this.$bridge.getTaxes(this.deviceId, this.objToJson({
					offset: isLoadMore ? this.taxes.offset + 20 : 0
				}))

				data = await (typeof data === 'string' ? JSON.parse(data) : data)

				this.taxes.data = data.offset ? this.taxes.data.concat(data.data) : data.data
				this.taxes.offset = data.offset
				this.taxes.total = data.total
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getTaxes(true)
				}
			},
			createTax () {
				this.selectedTax = null
				this.taxCreationModal.show = true
			},
			editTax (tax) {
				this.selectedTax = tax
				this.taxCreationModal.show = true
			},
			deleteTax (tax) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						const date = new Date()
						let charges = await this.$bridge.getCharges(
							this.deviceId, this.locationId, this.objToJson({
								tax_id: tax.id
							})
						)

						charges = (typeof charges === 'string' ? JSON.parse(charges) : charges).data
						const updateCharges = charges.map(charge => ({
							id: charge.id,
							tax: charge.tax.filter(t => t.id !== tax.id),
							updated_at: date
						}))

						await this.$bridge.updateMany('Charge', updateCharges)

						let priceCategories = await this.$bridge.getPriceCategories(
							this.deviceId, this.locationId, this.objToJson({
								tax_id: tax.id
							})
						)

						priceCategories = (typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories).data
						const updatePriceCategories = priceCategories.map(pc => ({
							id: pc.id,
							tax: pc.tax.filter(t => t.id !== tax.id),
							updated_at: date
						}))

						await this.$bridge.updateMany('PriceCategory', updatePriceCategories)

						let itemVariations = await this.$bridge.getItemVariations(
							this.deviceId, this.objToJson({
								tax_id: tax.id
							})
						)

						itemVariations = (typeof itemVariations === 'string' ? JSON.parse(itemVariations) : itemVariations).data
						const updateItemVariations = itemVariations.map(v => ({
							id: v.id,
							tax: v.tax.filter(t => t.id !== tax.id),
							updated_at: date
						}))

						await this.$bridge.updateMany('ItemVariation', updateItemVariations)
						tax = {
							id: tax.id,
							is_active: false,
							updated_at: date
						}

						await this.$bridge.insert(
							'Tax',
							this.bridgeName === 'ANDROID' ? this.objToJson(tax) : tax,
							true
						)
						await this.getTaxes()
					}
				})
			}
		}
	}
