
	let debounceTimer = null

	export default {
		data () {
			return {
				loading: true,
				shifts: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedShift: null
			}
		},
		computed: {
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			settings () {
				return this.$store.state.settings
			}
		},
		async beforeMount () {
			await this.getShifts({})
			this.selectedShift = this.shifts.data[0]
			this.loading = false
			requestAnimationFrame(() => this.$refs.shiftsBlock?.addEventListener('scroll', this.loadMoreShifts))
		},
		methods: {
			async getShifts ({ id, offset }) {
				const filters = {
					id,
					device_id: this.deviceId,
					merchant_id: this.merchant.id,
					offset
				}

				if (!+this.settings.general.multi_employee_kiosk) {
					filters.employee_id = this.employee.id
				}

				let shifts = await this.$store.dispatch('bridgeCall', {
					methodName: 'getCashDrawerShifts',
					args: [this.deviceId, this.objToJson(filters)]
				})

				shifts = typeof shifts === 'string' ? JSON.parse(shifts) : shifts

				if (shifts.data.length) {
					if (offset) {
						this.shifts = {
							data: this.shifts.data.concat(shifts.data),
							total: shifts.total,
							offset: shifts.offset
						}
					} else {
						this.shifts = shifts
					}
				}
			},
			selectShift ($event, shift) {
				$event.currentTarget.closest('.list-group').childNodes.forEach((list) => {
					list.classList.remove('active')
				})
				$event.currentTarget.classList.add('active')
				this.selectedShift = shift
			},
			loadMoreShifts ($event) {
				const el = $event.currentTarget

				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					if (
						this.shifts.data.length < this.shifts.total &&
						Math.round(el.scrollTop) > (el.scrollHeight - el.offsetHeight) - 10
					) {
						this.getShifts({ offset: this.shifts.offset + (this.appVersionNumber >= 4024 ? 20 : 50) })
					}
				}, 100)
			},
			getEventData (type, cashVia) {
				const events = this.selectedShift.events.filter((event) => {
					return event.type === type && (!cashVia || (cashVia && event.cash_via === cashVia))
				})

				return {
					count: events.length,
					sum: events.length ? events.reduce((sum, event) => sum + event.amount, 0) : 0
				}
			}
		}
	}
