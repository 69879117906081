
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			customerProp: {
				type: Object,
				default: null
			},
			orderProp: {
				type: Object,
				default: null
			}
		},
		data () {
			return {
				customer: null,
				amount: 0,
				outstandingAmount: 0,
				paymentMethod: null,
				notes: '',
				paymentMethods: [],
				isPaid: false
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			tidyPay () {
				return this.$store.state.tidyPay
			},
			clover () {
				return this.$store.state.clover
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		async beforeMount () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.paymentMethods = (typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods)
				.reduce((paymentMethods, paymentMethod) => {
					if (!['credit', 'split'].includes(paymentMethod.slug)) {
						paymentMethods.push(paymentMethod)
					}

					return paymentMethods
				}, [])
			this.paymentMethod = this.paymentMethods[0] || null
		},
		methods: {
			initModal () {
				this.customer = { ...(this.orderProp?.customer || this.customerProp) }

				if (this.orderProp) {
					this.outstandingAmount = this.$currency.transformNumber(this.orderProp.due_amount)
				} else if (this.customer?.id !== null) {
					this.outstandingAmount = this.$currency.transformNumber(this.customer.credit - this.customer.debit)
				}

				this.amount = this.outstandingAmount
			},
			async payCredit () {
				if (await this.$refs.validator.validate()) {
					if (this.paymentMethod.slug === 'card') {
						if (this.$offline.state === 'up') {
							if (!this.isPaid) {
								try {
									if (this.tidyPay.enabled) {
										await this.$store.dispatch('initTidyPay', {
											amount: parseFloat(this.amount * 100)
										})
										this.isPaid = true

										return await this.payCredit()
									} else if (this.clover.enabled) {
										const response = await this.$store.dispatch('initClover', this.amount)

										if (response.data?.payment?.result === 'SUCCESS') {
											this.isPaid = true

											return await this.payCredit()
										} else {
											return
										}
									}
								} catch (err) {
									console.error(err)

									return
								}
							}
						} else {
							return this.$swal({
								title: this.$t('offlineError.title'),
								text: this.$t('offlineError.text'),
								icon: 'error',
								button: this.$t('ok')
							})
						}
					}

					const date = new Date()
					const id = this.getUniqueId()
					const code = `${this.deviceId}${date.valueOf()}`
					const credit = {
						id,
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						employee_id: this.employee.id,
						employee_shift_id: this.employeeShift.id,
						customer_id: this.customer.id,
						order_id: this.orderProp?.id,
						credit_code: code,
						amount: this.orderProp?.original_due_amount < parseFloat(this.amount) ? this.orderProp.original_due_amount : parseFloat(this.amount),
						payment_method: this.paymentMethod.slug,
						type: 'debit',
						notes: this.notes,
						custom_attributes: this.objToJson({
							source: 'complete'
						}),
						is_synced: !!this.isMiniPlan,
						created_at: date,
						updated_at: date
					}
					const customer = {
						id: this.customer.id,
						debit: this.customer.debit + parseFloat(this.amount),
						updated_at: date
					}

					if (!this.isMiniPlan) {
						const syncData = {
							id: this.getUniqueId(+credit.id + 1),
							model_id: credit.id,
							model_name: 'customer-credit',
							payload: this.objToJson({
								model_id: credit.id,
								employee_shift_code: this.employeeShift.shift_code,
								customer_id: !this.customer.customer_id ? this.customer.id : '',
								merchant_customer_id: this.customer.customer_id,
								credit_code: credit.credit_code,
								receipt_code: this.orderProp?.receipt_code,
								amount: credit.amount,
								payment_method: credit.payment_method,
								type: credit.type,
								notes: credit.notes,
								currency_code: this.merchant.currencyCode,
								custom_attributes: JSON.parse(credit.custom_attributes)
							})
						}

						await this.$store.dispatch('bridgeCall', {
							methodName: 'insert',
							args: [
								'Sync',
								this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
								true
							]
						})
					}

					if (this.paymentMethod.slug === 'cash') {
						const event = {
							id,
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employeeShift.id,
							cash_drawer_shift_id: this.cashDrawerShift.id,
							amount: parseFloat(this.amount),
							cash_via: 'credit',
							type: 'cash_in',
							is_synced: !!this.isMiniPlan,
							updated_at: date
						}

						await this.$store.dispatch('bridgeCall', {
							methodName: 'insert',
							args: [
								'CashDrawerShiftEvent',
								this.bridgeName === 'ANDROID' ? this.objToJson(event) : event,
								true
							]
						})

						if (!this.isMiniPlan) {
							const syncData = {
								id: this.getUniqueId(+credit.id + 2),
								model_id: event.id,
								model_name: 'cash-drawer-shift-event',
								payload: this.objToJson({
									model_id: event.id,
									employee_shift_code: this.employeeShift.shift_code,
									cash_drawer_shift_id: event.cash_drawer_shift_id,
									employee_id: event.employee_id,
									shift_event_code: code,
									event_type: event.type,
									cash_via: event.cash_via,
									event_money: event.amount
								})
							}

							await this.$store.dispatch('bridgeCall', {
								methodName: 'insert',
								args: [
									'Sync',
									this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
									true
								]
							})
						}
					}

					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Customer',
							this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
							true
						]
					})
					this.$refs.payCreditModal?.hide()
					this.$swal({
						title: `${this.$options.filters.capitalize(this.$tc('success', 1))}!`,
						text: this.$t('creditSuccess'),
						icon: 'success',
						button: this.$t('ok')
					})
					this.$emit('update:payCredit', {
						...credit,
						customer: { ...customer }
					})
				}
			},
			resetModal () {
				this.isPaid = false
				this.$emit('hidden')
			}
		}
	}
