import { render, staticRenderFns } from "./pay-credit-modal.vue?vue&type=template&id=63450a4a"
import script from "./pay-credit-modal.vue?vue&type=script&lang=js"
export * from "./pay-credit-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Denominations: require('/home/ubuntu/complete-nuxt-js/components/denominations.vue').default})
