
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				amount: '0.00',
				description: '',
				cashVia: 'other',
				paidForOptions: [
					{
						text: 'vendor purchase',
						value: 'purchase'
					},
					{
						text: 'employee salary',
						value: 'salary'
					},
					{
						text: 'expense',
						value: 'expense'
					},
					{
						text: 'other',
						value: 'other'
					}
				],
				cashDrawerShiftEvents: [],
				availableCash: 0
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.merchant || this.$store.state.selectedMerchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawer () {
				return this.$store.state.cashDrawer
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			settings () {
				return this.$store.state.settings
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		async beforeMount () {
			if (this.cashDrawer.type === 'closing') {
				const cashDrawerShiftEvents = await this.$store.dispatch('bridgeCall', {
					methodName: 'getCashDrawerShiftEvents',
					args: [this.cashDrawerShift.id]
				})

				this.cashDrawerShiftEvents = typeof cashDrawerShiftEvents === 'string'
					? JSON.parse(cashDrawerShiftEvents)
					: cashDrawerShiftEvents

				this.description = this.cashDrawerShift.description
				this.availableCash = parseFloat(this.cashDrawerShift.starting_cash_amount) +
					this.getEventData('cash_in').sum - this.getEventData('cash_out').sum
			}
		},
		mounted () {
			if (['starting', 'closing'].includes(this.cashDrawer.type)) {
				this.$bridge.openCashDrawer(this.bridgeName === 'ANDROID' ? this.objToJson(this.printerSettings) : this.printerSettings)
			}
		},
		methods: {
			async processShift () {
				if (await this.$refs.validator.validate()) {
					const type = this.cashDrawer.type
					const date = new Date()
					const id = this.getUniqueId()
					const code = `${this.deviceId}${this.locationId}${date.valueOf()}`
					let shift = this.cashDrawerShift
						? await this.$store.dispatch('bridgeCall', {
							methodName: 'getCashDrawerShifts',
							args: [this.deviceId, this.objToJson({ id: this.cashDrawerShift.id })]
						})
						: null

					shift = typeof shift === 'string' ? JSON.parse(shift) : shift

					if (shift && shift.data.length) {
						shift = shift.data[0]
					}

					if (type === 'starting') {
						shift = {
							id,
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employeeShift.id,
							shift_code: code,
							starting_cash_amount: parseFloat(this.amount),
							description: this.description,
							opened_at: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
							is_synced: !!this.isMiniPlan,
							updated_at: date
						}
					} else if (type === 'closing' && shift) {
						shift.description = this.description
						shift.closed_at = this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
						shift.closed_cash_amount = parseFloat(this.amount)
					}

					if (shift && (type === 'starting' || type === 'closing')) {
						this.$store.commit('setState', {
							key: 'cashDrawerShift',
							value: shift,
							save: true
						})

						if (type === 'starting') {
							this.$store.dispatch('bridgeCall', {
								methodName: 'insert',
								args: [
									'CashDrawerShift',
									this.bridgeName === 'ANDROID' ? this.objToJson(shift) : shift,
									true
								]
							})

							if (!this.isMiniPlan) {
								const syncData = {
									id,
									model_id: shift.id,
									model_name: 'cash-drawer-shift',
									payload: this.objToJson({
										model_id: shift.id,
										merchant_id: this.merchant.id,
										device_id: this.deviceId,
										shift_code: shift.shift_code,
										cash_drawer_status: type === 'starting' ? 'open' : 'closed',
										employee_shift_code: this.employeeShift.shift_code,
										opened_at: shift.opened_at,
										opening_employee_id: this.employee.id,
										starting_cash_money: shift.starting_cash_amount,
										description: shift.description
									})
								}

								this.$store.dispatch('bridgeCall', {
									methodName: 'insert',
									args: [
										'Sync',
										this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
										true
									]
								})
							}
						}
					} else if (shift && (type === 'cash in' || type === 'cash out')) {
						const event = {
							id,
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employeeShift.id,
							cash_drawer_shift_id: this.cashDrawerShift.id,
							amount: parseFloat(this.amount),
							cash_via: this.cashVia,
							type: type.replace(' ', '_'),
							description: this.description,
							is_synced: !!this.isMiniPlan,
							updated_at: date
						}

						this.$store.dispatch('bridgeCall', {
							methodName: 'insert',
							args: [
								'CashDrawerShiftEvent',
								this.bridgeName === 'ANDROID' ? this.objToJson(event) : event,
								true
							]
						})

						if (!this.isMiniPlan) {
							const syncData = {
								id,
								model_id: event.id,
								model_name: 'cash-drawer-shift-event',
								payload: this.objToJson({
									model_id: event.id,
									cash_drawer_shift_id: event.cash_drawer_shift_id,
									employee_id: event.employee_id,
									event_type: event.type,
									cash_via: event.cash_via,
									event_money: event.amount,
									shift_event_code: code,
									employee_shift_code: this.employeeShift.shift_code,
									description: this.description
								})
							}

							this.$store.dispatch('bridgeCall', {
								methodName: 'insert',
								args: [
									'Sync',
									this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
									true
								]
							})
						}

						this.$bridge.openCashDrawer(this.bridgeName === 'ANDROID' ? this.objToJson(this.printerSettings) : this.printerSettings)
					}

					if (shift) {
						this.$store.commit('resetCashDrawer')
						this.$root.$emit('update-cash-event')

						if (type === 'closing') {
							this.$store.commit('setPasscode', {
								show: true,
								type: 'clockout',
								employee: this.employee
							})
						} else {
							this.$swal({
								title: this.$options.filters.capitalize(this.$tc('success', 1)) + '!',
								text: this.$t(
									type === 'starting'
										? 'cashShiftStarted'
										: (type === 'cash in' ? 'cashReceivedSuccess' : 'cashPaidSuccess')
								),
								icon: 'success',
								button: this.$t('ok')
							})
						}
					}
				}
			},
			getEventData (type, cashVia) {
				const events = this.cashDrawerShiftEvents.filter((event) => {
					return event.type === type && (!cashVia || (cashVia && event.cash_via === cashVia))
				})

				return {
					count: events.length,
					sum: events.length ? events.reduce((sum, event) => sum + event.amount, 0) : 0
				}
			}
		}
	}
