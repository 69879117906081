
	import DateRangePicker from 'vue2-daterange-picker'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			DateRangePicker,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				discounts: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedDiscount: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				],
				discountsModal: null,
				selectedBuyCriteria: 'order',
				daysInAWeek: [
					{
						text: 'sunday'
					},
					{
						text: 'monday'
					},
					{
						text: 'tuesday'
					},
					{
						text: 'wednesday'
					},
					{
						text: 'thursday'
					},
					{
						text: 'friday'
					},
					{
						text: 'saturday'
					}
				],
				discount: {
					name: null,
					daysInAWeek: null,
					validity: {
						startDate: null,
						endDate: null
					}
				},
				buyCriteria: [
					{
						text: 'select buy criteria',
						value: null
					},
					{
						text: 'order',
						value: 'order'
					}
				],
				getCriteria: [
					{
						text: 'select get Criteria',
						value: null
					},
					{
						text: 'subtotal',
						value: 'subtotal'
					}
				],
				selectedGetCriteria: 'subtotal',
				typeOrder: [
					{
						text: 'select type Order',
						value: null
					},
					{
						text: 'sub total',
						value: 'subtotal'
					}
				],
				buyConditionValue: null,
				buyConditionType: 'price',
				discountValue: null,
				variation: null,
				buyVariation: null,
				variations: [],
				getDiscountValue: null,
				getDiscountType: null,
				getMaxDiscountQuantity: null,
				getDiscountQuantity: null,
				applyAutomatically: false,
				getMaxDiscountValue: null
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			locationId () {
				return this.$store.state.locationId
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			settings () {
				return this.$store.state.settings
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getDiscounts()
		},
		methods: {
			async getDiscounts (isLoadMore) {
				let data = await this.$bridge.getDiscounts(this.deviceId, this.locationId, this.objToJson({
					offset: isLoadMore ? this.discounts.offset + 20 : 0
				}))

				data = await (typeof data === 'string' ? JSON.parse(data) : data)

				this.discounts.data = data.offset ? this.discounts.data.concat(data.data) : data.data
				this.discounts.offset = data.offset
				this.discounts.total = data.total

				this.discounts.data = this.discounts.data.filter(c => c.entity !== 'dynamic')
				this.discounts.total -= 1
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getDiscounts(true)
				}
			},
			createDiscount () {
				this.cart.selectedDiscount = null
				window.requestAnimationFrame(() => this.$refs.discountsModal.show())
			},
			editDiscount (discount) {
				this.cart.selectedDiscount = discount
				window.requestAnimationFrame(() => this.$refs.discountsModal.show())
			},
			deleteDiscount (discount) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						discount = {
							id: discount.id,
							is_active: false,
							updated_at: new Date()
						}

						await this.$bridge.insert(
							'Discount',
							this.bridgeName === 'ANDROID' ? this.objToJson(discount) : discount,
							true
						)
						await this.getDiscounts()
					}
				})
			},
			async initModal () {
				if (this.cart.selectedDiscount) {
					let variation = null

					if (this.cart.selectedDiscount.discount_items.length) {
						const data = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
							merchant_id: this.merchant.id,
							id: this.cart.selectedDiscount.discount_items[0].variation_id
						}))

						variation = (typeof data === 'string' ? JSON.parse(data) : data).data[0]
					}

					this.discount = {
						name: this.cart.selectedDiscount.name,
						daysInAWeek: this.cart.selectedDiscount.custom_attributes.days?.map(d => ({ text: d })) || [],
						validity: {
							startDate: this.cart.selectedDiscount?.valid_from || null,
							endDate: this.cart.selectedDiscount?.expires_on || null
						}
					}
					this.selectedBuyCriteria = 'order'
					this.selectedGetCriteria = this.cart.selectedDiscount.entity === 'all' ? 'subtotal' : 'item'
					this.buyConditionValue = this.cart.selectedDiscount.discount_items[0]?.buy_condition_value || this.cart.selectedDiscount.buy_condition_value
					this.buyConditionType = this.cart.selectedDiscount.discount_items[0]?.buy_condition_type || this.cart.selectedDiscount.buy_condition_type
					this.getDiscountValue = this.cart.selectedDiscount.discount_items[0]?.get_discount_value || this.cart.selectedDiscount.get_discount_value
					this.getDiscountType = this.cart.selectedDiscount.discount_items[0]?.get_discount_type || this.cart.selectedDiscount.get_discount_type
					this.getMaxDiscountQuantity = this.cart.selectedDiscount.discount_items[0]?.get_discount_max_quantity || null
					this.getDiscountQuantity = this.cart.selectedDiscount.discount_items[0]?.get_discount_quantity || null
					this.applyAutomatically = this.cart.selectedDiscount.is_automatic
					this.getMaxDiscountValue = this.cart.selectedDiscount.max_discount_value
					this.buyVariation = variation
				}
			},
			async getItemVariations (searchTerm) {
				let variations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					search_term: searchTerm
				}))

				variations = (typeof variations === 'string' ? JSON.parse(variations) : variations)
				this.variations = variations.data || variations
			},
			async processDiscountCreation () {
				if (await this.$refs.discountValidator.validate()) {
					const date = new Date()
					const customAttributes = {}

					if (this.discount.daysInAWeek?.length) {
						customAttributes.days = this.discount.daysInAWeek.map(d => d.text)
					}

					const discount = {
						id: this.cart.selectedDiscount?.id || date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						location_id: this.locationId,
						name: this.discount.name,
						description: this.discount.name,
						buy_anything: false,
						buy_condition_type: 'price',
						buy_condition_value: +this.buyConditionValue,
						get_discount_type: this.selectedGetCriteria === 'subtotal' ? this.getDiscountType : null,
						get_discount_value: this.selectedGetCriteria === 'subtotal' ? +this.getDiscountValue : 0,
						max_discount_value: this.selectedGetCriteria === 'subtotal' ? this.getDiscountType === 'percentage' ? +this.getMaxDiscountValue : +this.getDiscountValue : 0,
						entity: this.selectedGetCriteria === 'subtotal' ? 'all' : this.selectedGetCriteria,
						discount_items: this.buyVariation
							? [{
								id: this.buyVariation.id,
								discount_id: this.cart.selectedDiscount?.id || date.valueOf(),
								variation_id: this.buyVariation.id,
								buy_batch_id: null,
								get_batch_id: null,
								buy_condition_type: null,
								buy_condition_value: 0,
								get_discount_type: this.getDiscountType,
								get_discount_value: +this.getDiscountValue,
								get_discount_quantity: +this.getDiscountQuantity,
								get_discount_max_quantity: +this.getMaxDiscountQuantity,
								updated_at: new Date()
							}]
							: [],
						discount_categories: [],
						customers: [],
						customer_groups: [],
						valid_from: this.discount.validity?.startDate?.toString() || null,
						expires_on: this.discount.validity?.endDate?.toString() || null,
						get_free_shipping: false,
						apply_discount_sub_total: true,
						limit: 0,
						used_discount_count: 0,
						custom_attributes: this.objToJson(customAttributes),
						is_automatic: this.applyAutomatically,
						is_active: true,
						updated_at: date
					}

					await this.$bridge.insert(
						'Discount',
						this.bridgeName === 'ANDROID' ? this.objToJson(discount) : discount,
						true
					)
					this.$refs.discountsModal.hide()
					await this.getDiscounts()
				}
			},
			resetModel () {
				this.discount = {
					name: null,
					daysInAWeek: null,
					validity: {
						startDate: null,
						endDate: null
					}
				}
				this.selectedBuyCriteria = 'order'
				this.selectedGetCriteria = 'subtotal'
				this.buyConditionValue = null
				this.buyConditionType = 'price'
				this.getDiscountValue = null
				this.getDiscountType = null
				this.getMaxDiscountQuantity = null
				this.getDiscountQuantity = null
				this.applyAutomatically = false
				this.getMaxDiscountValue = null
				this.buyVariation = null
			}
		}
	}
