
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				floors: {},
				tableOrders: [],
				fields: [
					{
						key: 'tables',
						label: 'table',
						pluralizationIndex: 2,
						tdClass: 'align-middle'
					}, {
						key: 'receipt_code',
						label: 'order number',
						tdClass: 'align-middle'
					}, {
						key: 'price_category',
						label: 'price category',
						tdClass: 'align-middle'
					}, {
						key: 'created_by',
						label: 'waiter',
						tdClass: 'align-middle'
					}, {
						key: 'customer',
						label: 'customer',
						tdClass: 'align-middle'
					}, {
						key: 'total_price',
						label: 'total',
						tdClass: 'align-middle'
					}, {
						key: 'created_at',
						label: 'created at',
						tdClass: 'align-middle'
					}, {
						key: 'selected',
						label: 'selected',
						tdClass: 'align-middle text-center'
					}
				],
				isEditOrder: null,
				disableDone: false,
				numberOfGuest: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			selectedTables: {
				get () {
					return this.$store.state.selectedTables
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedTables',
						value
					})
				}
			},
			selectedOrder: {
				get () {
					return this.$store.state.selectedOrder
				},
				set (order) {
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: order
					})
				}
			}
		},
		methods: {
			init () {
				this.disableDone = false
				this.isEditOrder = this.selectedOrder !== null
				this.getFloors()
				this.getTableOrders()
			},
			async getFloors () {
				const floors = await this.$store.dispatch('bridgeCall', {
					methodName: 'getFloors',
					args: [this.deviceId, this.locationId, this.objToJson({
						merchant_id: this.merchant.id,
						offset: -1
					})]
				})

				this.floors = typeof floors === 'string' ? JSON.parse(floors) : floors
				this.floors = this.floors.data || this.floors
			},
			selectTable (table) {
				const tableIndex = this.selectedTables.findIndex(t => t.id === table.id)

				if (tableIndex !== -1) {
					this.selectedTables.splice(tableIndex, 1)
				} else if (table.status !== 'blocked') {
					this.selectedTables.push(table)
				}

				this.getTableOrders()
			},
			async getTableOrders () {
				if (this.selectedTables.length) {
					const tableOrders = await this.$store.dispatch('bridgeCall', {
						methodName: 'getOrders',
						args: [this.deviceId, this.objToJson({
							table_id: this.selectedTables.map(t => t.id),
							status: 'pending'
						})]
					})

					this.tableOrders = (
						typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders
					).data.reverse().map((o) => {
						if (o.price_category?.id !== this.cart.priceCategory?.id) {
							o._rowVariant = 'danger'
						}

						return o
					})
				} else {
					this.tableOrders = []
				}

				setTimeout(() => {
					if (this.tableOrders.length && this.selectedOrder) {
						const orderIndex = this.tableOrders.findIndex(o => o.id === this.selectedOrder.id)

						if (orderIndex !== -1 && this.$refs.ordersTable) {
							this.$refs.ordersTable.selectRow(orderIndex)
						}
					}
				}, 0)
			},
			selectOrder (orders) {
				const order = orders[0] || null

				if (!order || order?.price_category?.id === this.cart.priceCategory?.id) {
					this.selectedOrder = order
				} else if (order) {
					this.$refs.ordersTable.unselectRow(this.tableOrders.findIndex(o => o.id === order.id))
					this.$refs.ordersTable.selectRow(this.tableOrders.findIndex(o => o.id === this.selectedOrder?.id))
				}
			},
			async processTables () {
				this.disableDone = true

				if (await this.$refs.validator.validate() && this.selectedTables.length) {
					this.$store.commit('setCart', {
						numberOfGuest: this.numberOfGuest,
						tableStartTime: new Date()
					})
					this.$emit('update:processOrder', this.isEditOrder)
				} else {
					this.disableDone = false
				}
			},
			closeTablesModal () {
				this.selectedTables = []
				this.$bvModal.hide('tables-modal')
			}
		}
	}
